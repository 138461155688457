import React, { Component } from 'react';
import axios from 'axios';
import constants from '../constants';

import masterChiefABI from '../abis/masterchief.json';
import erc20ABI from '../abis/erc20.json';

export default class Tools extends Component {

  state = {
    poolId: 0,
    masterChiefAddress: constants.masterChiefAddress,
    account: this.props.account,
    approvals: [],
    tokenNameDb: {},
  };

  herodashFire = this.props.herodashFire;

  componentDidUpdate() {
    if (this.state.account !== this.props.account) {
      this.setState({ account: this.props.account, approvals: [] });
    }
  }

  emergencyWithdraw = () => {
    const { poolId, masterChiefAddress, account } = this.state;
    if (!poolId && poolId !== 0) return;
    if (!masterChiefAddress) return;
    if (account) {

      const masterChief = new this.props.web3.eth.Contract(masterChiefABI.abi, masterChiefAddress);

      masterChief.methods.emergencyWithdraw(
        poolId,
      ).send({ from: account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  getTokenName = async (address) => {
    const ERC20token = new this.props.web3.eth.Contract(erc20ABI.abi, address);
    const name = await ERC20token.methods.name().call();
    return name;
  }

  loadApprovals = () => {

    if (this.state.account) {
      axios.get(`https://api.bscscan.com/api?module=account&action=txlist&apikey=WGZPZ7E2YTJYFDE6MTR5EHPYKKTM64W21P&address=${this.state.account}`)
        .then(({ data }) => {
          const approveString = "0x095ea7b3000000000000000000000000";

          const approvals = data.result.filter(d => (d.input.startsWith(approveString) && parseInt(d.input.slice(75), 16) > 0)).map((d) => {
            return {
              token: d.to,
              amount: parseInt(d.input.slice(75), 16),
              spender: '0x' + d.input.slice(34, 74),
              spendername: constants.knownAddresses['0x' + d.input.slice(34, 74)],
              collapsed: true,
              tokenName: this.state.tokenNameDb[d.to],
            }
          });

          this.setState({ approvals });
        })
        .catch((e) => {
          this.props.showError("Couldn't load approvals");
        });
    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  revokeApproval = (index) => {
    if (this.state.account) {
      const { approvals } = this.state;

      const approval = approvals[index];


      if (!approval.spender || !approval.token) {
        return;
      }

      const ERC20token = new this.props.web3.eth.Contract(erc20ABI.abi, approval.token);

      ERC20token.methods.approve(
        approval.spender,
        0
      ).send({ from: this.state.account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.setState({
            approvals: approvals.map((ap, i) => index === i ? null : ap).filter(ap => ap),
          });
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  toggleCollapse = async (index) => {
    const { approvals, tokenNameDb } = this.state;

    const approval = approvals[index];

    if (approval.collapsed) {

      if (tokenNameDb[approval.token]) {
        this.setState({ approvals: approvals.map((ap, i) => index === i ? { ...ap, collapsed: false, tokenName: tokenNameDb[approval.token] } : ap) })
      } else {
        const name = await this.getTokenName(approval.token);
        if (name) {
          tokenNameDb[approval.token] = name;
          this.setState({
            tokenNameDb,
            approvals: approvals.map((ap, i) => index === i ? { ...ap, collapsed: false, tokenName: name } : ap),
          });
        } else {
          this.setState({
            approvals: approvals.map((ap, i) => index === i ? { ...ap, collapsed: false, tokenName: "" } : ap),
          });
        }
      }

    } else {
      this.setState({ approvals: approvals.map((ap, i) => index === i ? { ...ap, collapsed: true } : ap) })
    }
  }

  render() {

    const { masterChiefAddress, poolId, approvals } = this.state;

    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2 px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">HERODASH TOOLS</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">Emergency withdraw, Approval Checker & Remover</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-4 xl:mt-8 shadow-md">
            <p className='text-white font-poppins text-lg'><span className="material-icons-outlined text-other3 align-text-bottom">emergency</span>&nbsp; EMERGENCY WITHDRAW</p>
            <div className="mt-2">
              <label className="text-other2 font-poppins text-lg">MasterChef contract address</label>
              <input value={masterChiefAddress} onChange={(e) => this.setState({ masterChiefAddress: e.target.value })} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="text" />
            </div>
            <div className="mt-2">
              <label className="text-other2 font-poppins text-lg">Pool Id</label>
              <input value={poolId} onChange={(e) => this.setState({ poolId: e.target.value })} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
            </div>
            <div className="mt-4">
              {this.props.account === null && <button onClick={this.props.connectWallet} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">Connect wallet to withdraw</button>}
              {this.props.account !== null && <button onClick={this.emergencyWithdraw} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">Emergency Withdraw</button>}
            </div>
          </div>
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-4 xl:mt-8 shadow-md">
            <p className='text-white font-poppins text-lg'><span className="material-icons-outlined text-other3 align-text-bottom">verified_user</span>&nbsp; APPROVAL CHECKER & REMOVER</p>
            <div className="mt-4">
              {this.props.account === null && <button onClick={this.props.connectWallet} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">Connect wallet to check</button>}
              {this.props.account !== null && <div>
                {
                  approvals.length === 0 && <p className="text-white font-poppins">No Approval found! Click on *Check* to refresh</p>
                }
                {
                  approvals.map((ap, i) => (<div key={i} className="bg-primary-light pl-3 py-2 font-poppins text-white mt-1 rounded cursor-pointer" onClick={() => this.toggleCollapse(i)}>
                    {ap.spendername ?? ap.spender}
                    {ap.collapsed || <div>
                      <span className="text-other1 font-semibold">{ap.amount}</span> on <span className="text-other3 font-semibold">{ap.tokenName}</span>
                      <p className="text-right pr-2">
                        <button onClick={(e) => {
                          e.stopPropagation();
                          this.revokeApproval(i);
                        }} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 mt-2">Revoke</button>
                      </p>
                    </div>}
                  </div>))
                }
              </div>}
              {this.props.account !== null && <button onClick={this.loadApprovals} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full mt-2">Check</button>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
