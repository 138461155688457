import React, { Component } from 'react';
import LogoBig from '../assets/logo-big.svg';

export default class Header extends Component {
  render() {
    return (
      <div className="fixed top-0 w-full flex justify-between bg-primary text-white py-3 px-2 md:px-6 border-b border-yellow-600 z-50">
        <div className="flex space-x-4">
          <span onClick={this.props.toggleSidebarState} className="material-icons-outlined hover:opacity-70 cursor-pointer self-center">{this.props.fullSidebar ? 'menu_open' : 'menu'}</span>
          <a href="https://herodash.finance"><img src={LogoBig} alt="herodash logo" className="h-10 inline self-center" /></a>
        </div>
        <div>
          <button onClick={this.props.account ? null : this.props.connectWallet} className='p-2 px-4 bg-other3 font-poppins font-medium rounded-3xl hover:bg-opacity-50'>{this.props.account ? `${this.props.account.slice(0, 8)}...` : 'Connect'}</button>
        </div>
      </div>
    )
  }
}
