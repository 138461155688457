import React, { Component } from 'react';

import {
  Link,
} from "react-router-dom";

const links = [{
  text: "Home",
  icon: 'home',
  path: '/home',
}, {
  text: "Roadmap",
  icon: 'flag',
  path: '/roadmap',
}, {
  text: "Anti-Dump",
  icon: 'lock',
  path: '/antidump',
}, {
  text: "Buy",
  icon: 'shopping_cart',
  path: '/buy',
}, {
  text: "Swap",
  icon: 'swap_horiz',
  path: '/swap',
}, {
  text: "Add Liquidity",
  icon: 'water_drop',
  path: '/liquidity',
}, {
  text: "Staking",
  icon: 'layers',
  path: '/staking',
}, {
  text: "Deal tokens",
  icon: 'currency_exchange',
  path: '/deal',
}, {
  text: "Tools",
  icon: 'handyman',
  path: '/tools',
}, {
  text: "Audits",
  icon: 'security',
  path: '/audits',
}]

export default class Sidebar extends Component {
  render() {
    return (
      <div className={`mt-0 h-screen fixed duration-300 ease-out ${this.props.fullSidebar ? 'w-52' : 'w-20' } bg-primary border-t border-other3`}>
        <div className="mt-10">
          <ul className="font-poppins font-medium text-white space-y-5">
            {
              links.map((l, i) => (
                <Link key={i} to={l.path} onClick={() => this.props.setPage(l.path.slice(1))}>
                  <li className={`pl-6 leading-10 hover:bg-primary-light  pt-1 h-12 overflow-hidden ${this.props.page === l.path.slice(1) ? 'bg-gray-50 text-other3' : ''}  ${this.props.fullSidebar ? '' : '' }`}>
                    <span className="material-icons-outlined text-other3 align-text-bottom">{l.icon}</span> &nbsp;
                    <span className={`duration-500`}>{this.props.fullSidebar ? l.text : ''}</span>
                  </li>
                </Link>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }
}
