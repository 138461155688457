import React, { Component } from 'react';
import constants from '../constants';


export default class AddLiquidity extends Component {
  state = {
    amountIn: 0,
    amountIn2: 0,
    amountIn3: 0,
    remove: false,
    approveBnb: true,
    approveHerodash: true,
    approveBnbHerodashLp: true,
    allowedBnb: new this.props.web3.utils.BN('0'),
    allowedHerodash: new this.props.web3.utils.BN('0'),
    allowedBnbHerodashLp: new this.props.web3.utils.BN('0'),
    account: this.props.account,
    bnbBalance: 0,
    herodashBalance: 0,
    lpBalance: 0,
    totalLpSupply: 0,
    reserve0: 0,
    reserve1: 0,
    amountOut: 0,
    amountOut2: 0,
    amountOut3: 0,
  };

  pancakeRouter = this.props.pancakeRouter;

  componentDidMount() {
    this.checkAllowance();
    this.loadbalance();
  }

  componentDidUpdate() {
    if (this.state.account !== this.props.account) {
      this.setState({
        account: this.props.account, allowedBnb: new this.props.web3.utils.BN('0'),
        allowedHerodash: new this.props.web3.utils.BN('0'),
        allowedBnbHerodashLp: new this.props.web3.utils.BN('0'),
      },
        () => {
          this.checkAllowance();
          this.loadbalance();
        });
    }
  }

  getEquivalentAmount = (newAmount, isOne) => {
    newAmount = parseInt(newAmount * 10000) / 10000;

    if (isOne) {
      this.setState({ amountIn: newAmount });

      if (newAmount <= 0) {
        return this.setState({ amountIn2: 0 });
      }

      this.props.bnbHerodashPair.methods.getReserves().call()
        .then((res) => {
          this.setState({
            amountIn2: parseInt((parseInt(res['reserve0']) * newAmount / parseInt(res['reserve1'])) * 1000000) / 1000000,
            reserve0: parseInt(res['reserve1']),
            reserve1: parseInt(res['reserve0']),
            amountOut: parseInt((newAmount * this.state.totalLpSupply * 10000) / parseInt(res['reserve1'])) / 10000,
          }, this.checkAllowance);
        });

    } else {
      this.setState({ amountIn2: newAmount });

      if (newAmount <= 0) {
        return this.setState({ amountIn: 0 })
      }

      this.props.bnbHerodashPair.methods.getReserves().call()
        .then((res) => {
          this.setState({
            amountIn: parseInt((parseInt(res['reserve1']) * newAmount / parseInt(res['reserve0'])) * 10000) / 10000,
            reserve0: parseInt(res['reserve1']),
            reserve1: parseInt(res['reserve0']),
            amountOut: parseInt((newAmount * this.state.totalLpSupply * 10000) / parseInt(res['reserve0'])) / 10000,
          }, this.checkAllowance);
        });
    }
  }

  getEquivalentTokens = (newAmount) => {
    newAmount = parseInt(newAmount * 10000) / 10000;
    this.setState({ amountIn3: newAmount });

    if (newAmount <= 0) {
      return this.setState({ amountOut2: 0, amountIn3: 0 });
    }

    this.props.bnbHerodashPair.methods.getReserves().call()
      .then((res) => {
        this.setState({
          reserve0: parseInt(res['reserve0']),
          reserve1: parseInt(res['reserve1']),

          amountOut2: parseInt(newAmount * parseInt(res['reserve0']) * 10000 / this.state.totalLpSupply) / 10000,
          amountOut3: parseInt(newAmount * parseInt(res['reserve1']) * 10000 / this.state.totalLpSupply) / 10000,

        }, this.checkAllowance);
      });
  }

  loadbalance = () => {
    if (!this.state.account) {
      return;
    }
    Promise.all([
      this.props.herodash.methods.balanceOf(this.state.account).call(),
      this.props.web3.eth.getBalance(this.state.account),
      this.props.bnbHerodashPair.methods.balanceOf(this.state.account).call(),
      this.props.bnbHerodashPair.methods.totalSupply().call(),
    ])
      .then((rs) => {
        this.setState({
          bnbBalance: parseInt(parseInt(rs[1]) / 10 ** 13) / 100000,
          herodashBalance: parseInt(parseInt(rs[0]) / 10 ** 13) / 100000,
          lpBalance: parseInt(parseInt(rs[2]) / 10 ** 13) / 100000,
          totalLpSupply: parseInt(rs[3]),
        });
      });
  }

  checkAllowance = (onlyLp = false) => {
    if (!this.state.account) return;

    if (!onlyLp) {
      if (this.state.allowedBnb.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn}`, 'ether')).cmp(this.state.allowedBnb) === 1) {
          this.setState({ approveBnb: true });
        } else {
          this.setState({ approveBnb: false });
        }
      } else {
        this.props.bnb.methods.allowance(this.state.account, constants.pancakeRouterAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);


            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedBnb: allowance, approveBnb: true });
            } else {
              this.setState({ allowedBnb: allowance, approveBnb: false });
            }
          });
      }

      if (this.state.allowedHerodash.cmp(new this.props.web3.utils.BN('0')) === 1) {

        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn2}`, 'ether')).cmp(this.state.allowedHerodash) === 1) {
          this.setState({ approveHerodash: true });
        } else {
          this.setState({ approveHerodash: false });
        }
      } else {
        this.props.herodash.methods.allowance(this.state.account, constants.pancakeRouterAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);

            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn2}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedHerodash: allowance, approveHerodash: true });
            } else {
              this.setState({ allowedHerodash: allowance, approveHerodash: false });
            }
          });
      }
    }

    if (this.state.allowedBnbHerodashLp.cmp(new this.props.web3.utils.BN('0')) === 1) {
      if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn3}`, 'ether')).cmp(this.state.allowedBnbHerodashLp) === 1) {
        this.setState({ approveBnbHerodashLp: true });
      } else {
        this.setState({ approveBnbHerodashLp: false });
      }
    } else {
      this.props.bnbHerodashPair.methods.allowance(this.state.account, constants.pancakeRouterAddress).call()
        .then((res) => {
          const allowance = new this.props.web3.utils.BN(res);

          if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn3}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
            this.setState({ allowedBnbHerodashLp: allowance, approveBnbHerodashLp: true });
          } else {
            this.setState({ allowedBnbHerodashLp: allowance, approveBnbHerodashLp: false });
          }
        });
    }
  }

  approveBnb = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.bnb.methods.approve(constants.pancakeRouterAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveBnb: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  approveHerodash = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.herodash.methods.approve(constants.pancakeRouterAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveHerodash: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  approveBnbHerodash = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.bnbHerodashPair.methods.approve(constants.pancakeRouterAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveBnbHerodashLp: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  addLiquidity = () => {
    const { amountIn, amountIn2, account } = this.state;

    if (account) {

      this.pancakeRouter.methods.addLiquidityETH(
        constants.herodashAddress,

        this.props.web3.utils.toWei(`${amountIn}`, 'ether'),
        this.props.web3.utils.toWei(`${amountIn * 0.94}`, 'ether'),

        this.props.web3.utils.toWei(`${amountIn2}`, 'ether'),
        account,
        Date.now() + 1000 * 60 * 5,
      ).send({ from: account, value: this.props.web3.utils.toWei(`${amountIn2}`, 'ether') })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance();
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  removeLiquidity = () => {
    const { amountIn3, account, amountOut2, amountOut3 } = this.state;

    if (account) {

      this.pancakeRouter.methods.removeLiquidityETH(
        constants.herodashAddress,

        this.props.web3.utils.toWei(`${amountIn3}`, 'ether'),

        this.props.web3.utils.toWei(`${amountOut2 * 0.96}`, 'ether'),

        this.props.web3.utils.toWei(`${amountOut3 * 0.96}`, 'ether'),

        account,
        Date.now() + 1000 * 60 * 5,
      ).send({ from: account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance();
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  removeLiquidityAlt = () => {
    const { amountIn3, account, amountOut2, amountOut3 } = this.state;

    if (account) {

      this.pancakeRouter.methods.removeLiquidity(
        constants.herodashAddress,
        constants.wbnbAddress,

        this.props.web3.utils.toWei(`${amountIn3}`, 'ether'),

        this.props.web3.utils.toWei(`${amountOut2 * 0.96}`, 'ether'),

        this.props.web3.utils.toWei(`${amountOut3 * 0.96}`, 'ether'),

        account,
        Date.now() + 1000 * 60 * 5,
      ).send({ from: account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance();
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  render() {
    const {
      amountIn, amountIn2, amountIn3, remove,
      approveBnb, approveHerodash, account, bnbBalance, herodashBalance,
      lpBalance, approveBnbHerodashLp, amountOut, amountOut2, amountOut3
    } = this.state;

    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">HERODASH Liquidity</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">Add or remove liquidity</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-xl w-full px-3 md:px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <div className="text-center space-y-2 md:space-y-0 sm:space-x-4">
              <button onClick={() => this.setState({ remove: false })} className={`bg-primary-light ${remove ? 'text-gray-400' : 'text-white'}  px-2 rounded-md font-poppins font-semibold`}>Add Liquidity</button>
              <button onClick={() => this.setState({ remove: true })} className={`bg-primary-light ${remove ? 'text-white' : 'text-gray-400'}  px-2 rounded-md font-poppins font-semibold`}>Remove Liquidity</button>
            </div>
            <p className="text-white font-poppins text-lg mt-4 text-center font-semibold"><span className="material-icons-outlined text-other3 align-text-bottom">water_drop</span> {remove ? 'REMOVE' : 'ADD'} LIQUIDITY</p>
            {remove || <div>
              <div className="mt-3">
                <label className="text-other2 font-poppins font-semibold">HERODASH (You have {herodashBalance})</label>
                <input value={Number(amountIn).toString()} max={herodashBalance} onChange={(e) => this.getEquivalentAmount(e.target.value, true)} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
              </div>
              <p className="text-center mt-2">
                <span className="material-icons-outlined bg-primary-light text-white rounded-full p-1">add</span>
              </p>
              <div className="mt-2">
                <label className="text-other2 font-poppins font-semibold">BNB (You have {bnbBalance})</label>
                <input value={Number(amountIn2).toString()} max={bnbBalance} onChange={(e) => this.getEquivalentAmount(e.target.value, false)} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
              </div>
              <p className="text-white font-poppins text-lg mt-3 font-semibold">You will receive <span className="text-other1">~{amountOut}</span> BNB-HERODASH LP tokens</p>
              {account !== null && <div>
                {(!approveBnb && !approveHerodash) && <div className="mt-6">
                  <button onClick={this.addLiquidity} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">ADD</button>
                </div>}
                {approveBnb && <div className="mt-6">
                  <button onClick={this.approveBnb} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve BNB (For PancakeSwap)</button>
                </div>}
                {approveHerodash && <div className="mt-6">
                  <button onClick={this.approveHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve HERODASH (For PancakeSwap)</button>
                </div>}
              </div>}
            </div>}
            {remove && <div>
              <div className="mt-3">
                <label className="text-other2 font-poppins">BNB-HERODASH LP to remove (You have {lpBalance})</label>
                <input value={Number(amountIn3).toString()} max={lpBalance} onChange={(e) => this.getEquivalentTokens(e.target.value)} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
              </div>
              <p className="text-white font-poppins text-lg mt-3">You will receive <span className="text-other1">~{amountOut2}</span> HERODASH and <span className="text-other1">~{amountOut3}</span> BNB tokens</p>
              {account !== null && <div>
                {(!approveBnbHerodashLp) && <div className="mt-6">
                  <button onClick={this.removeLiquidityAlt} className="bg-other3 text-white w-full py-2 rounded-md font-poppins hover:bg-opacity-75">REMOVE</button>
                </div>}
                {approveBnbHerodashLp && <div className="mt-6">
                  <button onClick={this.approveBnbHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins hover:bg-opacity-75">Approve BNB-HERODASH LP (For PancakeSwap)</button>
                </div>}
              </div>}
            </div>}
            {account === null && <div className="mt-6">
              <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins hover:bg-opacity-75">Connect wallet</button>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}
