import {
  BrowserRouter as Router,
} from "react-router-dom";

import React, { Component } from 'react';

import Web3 from "web3";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

import Home from "./pages/Home";
import Roadmap from "./pages/Roadmap";
import Antidump from "./pages/Antidump";
import Buy from "./pages/Buy";
import Swap from "./pages/Swap";
import AddLiquidity from "./pages/AddLiquidity";
import Staking from "./pages/Staking";
import Audits from "./pages/Audits";
import Tools from "./pages/Tools";
import Deal from "./pages/Deal";

import herodashABI from './abis/herodash.json';
import pairABI from './abis/pair.json';
import herodashmasterchiefABI from './abis/herodashmasterchief.json';
import herodashFireABI from './abis/herodashfire.json';
import herodashSale from './abis/herodashsale.json';
import pancakeRouterABI from './abis/pancakeRouter.json';
import erc20ABI from './abis/erc20.json';

import constants from "./constants";

const pages = [
  "home",
  "roadmap",
  "buy",
  "swap",
  "liquidity",
  "staking",
  "audits",
  "charts",
  "listing",
  "tools",
  "antidump",
  "deal",
];

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullSidebar: true,
      page: "home",
      account: null,
      herodashInWallet: 0,
      herodashInWalletRaw: 0,
      herodashStacked: 0,
      herodashStackedRaw: 0,
      herodashToHarvest: 0,
      herodashValue: 0,
      tvl: 0,
      herodashPrice: 0,
      totalSupply: 0,
      totalSupplyRaw: 0,
      totalburned: 0,
      marketCap: 0,
      transferTax: 0,
      showErrorModal: false,
      showSuccessModal: false,
      successMessage: "",
      errorMessage: "",
      bnbPrice: 0,
      totalValueOfLiquidityPool: 0,
      herodashAmountInLiquidityPool: 0,
      showTransactionSubmitedModal: false,
      transactionHash: "",
      transactionComplete: false,
      transactionSuccess: false,
    };
  }

  componentDidMount() {
    let { pathname } = window.location;
    pathname = pathname.split("/").filter((p) => p !== "")[0];

    if (pages.includes(pathname)) {
      this.setPage(pathname);
    } else {
      pathname = 'home';
      this.setPage('home');
    }

    // const provider = await detectEthereumProvider();
    // if (provider) {
    //   this.web3 = new Web3(window.ethereum.currentProvider);
    // }

    if (window.ethereum) {

      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });

      if (window.ethereum.networkVersion === "56" || window.ethereum.chainId === 56) {
        this.ethereum = window.ethereum;

        this.web3 = new Web3(window.ethereum);

        if (window.ethereum.accounts) {
          this.setState({ account: window.ethereum.accounts[0] });
        } else if (window.ethereum.address) {
          this.setState({ account: window.ethereum.address });
        }

        window.ethereum.on('accountsChanged', (accounts) => {
          if (accounts && accounts.length) {
            this.setState({ account: accounts[0] }, () => {
              this.loadUserStats(false);
            });

          }
        });

      } else {
        this.web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io/'));
        this.showError("Please connect to BSC Mainet");
      }
    } else {
      this.web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io/'));
    }

    this.bnbBusdPair = new this.web3.eth.Contract(pairABI.abi, constants.bnb_busd_pairAddress);
    this.bnbHerodashPair = new this.web3.eth.Contract(pairABI.abi, constants.bnb_herodash_pairAddress);
    this.herodashMasterchief = new this.web3.eth.Contract(herodashmasterchiefABI.abi, constants.masterChiefAddress);
    this.herodash = new this.web3.eth.Contract(herodashABI.abi, constants.herodashAddress);
    this.herodashFire = new this.web3.eth.Contract(herodashFireABI.abi, constants.herodashFireAddress);
    this.herodashSale = new this.web3.eth.Contract(herodashSale.abi, constants.herodashSaleAddress);
    this.pancakeRouter = new this.web3.eth.Contract(pancakeRouterABI.abi, constants.pancakeRouterAddress);
    this.bnb = new this.web3.eth.Contract(erc20ABI.abi, constants.wbnbAddress);

    // this.cake = new this.web3.eth.Contract(erc20ABI.abi, constants.cakeAddress);
    // this.busd = new this.web3.eth.Contract(erc20ABI.abi, constants.busdAddress);
    // this.btcb = new this.web3.eth.Contract(erc20ABI.abi, constants.btcbAddress);

    if (window.screen.width < 640) {
      this.toggleSidebarState();
    }

    this.loadUserStats();
    this.loadHerodashStats();
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: '',
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: '',
        })
      }, delay * 1000);
    })
  }

  showTransaction = (transactionHash, complete, success, delay = 10) => {
    this.setState({
      showTransactionSubmitedModal: true,
      transactionHash: transactionHash,
      transactionComplete: complete,
      transactionSuccess: success,
    }, () => {
      setTimeout(() => {
        this.setState({
          showTransactionSubmitedModal: false,
          transactionHash: '',
        })
      }, delay * 1000);
    })
  }

  loadUserStats(loop = true) {

    if (this.state.account) {
      // Get herodash balance
      this.herodash.methods.balanceOf(this.state.account)
        .call()
        .then((r) => {
          this.setState({
            herodashInWallet: this.numberWithCommas(parseInt(this.web3.utils.fromWei(r, 'ether') * 1000) / 1000),
            herodashInWalletRaw: parseFloat(this.web3.utils.fromWei(r, 'ether')),
          });
        });

      // Get stacked herodash
      this.herodashMasterchief.methods.userInfo(0, this.state.account)
        .call()
        .then((r) => {
          this.setState({
            herodashStacked: this.numberWithCommas(this.web3.utils.fromWei(r.amount, 'ether')),
            herodashStackedRaw: parseFloat(this.web3.utils.fromWei(r.amount, 'ether')),
          });
        });

      // Get herodash to harvest
      const ps = [
        new Promise((resolve) => setTimeout(() => resolve(0), 1000)),
      ];
      for (let i = 0; i < constants.poolCount2; i += 1) {
        ps.push(
          this.herodashMasterchief.methods.pendingHerodash(i, this.state.account).call(),
        );
      }

      Promise.all(ps)
        .then((rs) => {
          const totalHerodash = (rs.map(r => parseInt(r, 10)).reduce((acc, val) => acc + val, 0)) / (10 ** 18);
          this.setState({
            herodashToHarvest: parseInt(totalHerodash * 10000) / 10000,
            herodashValue: this.numberWithCommas(parseInt(
              (this.state.herodashInWalletRaw + this.state.herodashStackedRaw + parseInt(totalHerodash * 10000) / 10000)
              * this.state.herodashPrice * 100,
              10) / 100),
          });
        });
    }

    if (loop) {
      setInterval(() => {
        if (this.state.account) {
          // Get herodash balance
          this.herodash.methods.balanceOf(this.state.account)
            .call()
            .then((r) => {
              this.setState({
                herodashInWallet: this.numberWithCommas(parseInt(this.web3.utils.fromWei(r, 'ether') * 1000) / 1000),
                herodashInWalletRaw: parseFloat(this.web3.utils.fromWei(r, 'ether')),
              });
            });

          // Get stacked herodash
          this.herodashMasterchief.methods.userInfo(0, this.state.account)
            .call()
            .then((r) => {
              this.setState({
                herodashStacked: this.numberWithCommas(this.web3.utils.fromWei(r.amount, 'ether')),
                herodashStackedRaw: parseFloat(this.web3.utils.fromWei(r.amount, 'ether')),
              });
            });

          // Get herodash to harvest
          const ps = [];
          for (let i = 0; i < constants.poolCount2; i += 1) {
            ps.push(
              this.herodashMasterchief.methods.pendingHerodash(i, this.state.account).call(),
            );
          }

          Promise.all(ps)
            .then((rs) => {
              const totalHerodash = (rs.map(r => parseInt(r, 10)).reduce((acc, val) => acc + val, 0)) / (10 ** 18);

              this.setState({
                herodashToHarvest: parseInt(totalHerodash * 10000) / 10000,
                herodashValue: this.numberWithCommas(parseInt(
                  (this.state.herodashInWalletRaw + this.state.herodashStackedRaw + parseInt(totalHerodash * 10000) / 10000)
                  * this.state.herodashPrice * 100,
                  10) / 100),
              });
            });
        }
      }, 30000);
    }

  }

  loadHerodashStats(loop = true) {

    // Get total supply
    this.herodash.methods.totalSupply()
      .call()
      .then((r) => {
        this.setState({
          totalSupply: this.numberWithCommas(parseInt(this.web3.utils.fromWei(r, 'ether') * 1000) / 1000),
          totalSupplyRaw: parseInt(this.web3.utils.fromWei(r, 'ether'), 10),
          totalburned: this.numberWithCommas(constants.capSupply - parseInt(this.web3.utils.fromWei(r, 'ether'))),
        });
      });

    // Get tax 
    this.herodash.methods.TAX_ON_EVERY_TX_IN_POINTS()
      .call()
      .then((r) => {
        this.setState({ transferTax: parseInt(r, 10) / 100 });
      });

    // Get bnb price then herodash price
    this.bnbBusdPair.methods.getReserves()
      .call()
      .then((r) => {
        const bnbPriceInUsd = (parseInt(r.reserve1, 10) / parseInt(r.reserve0, 10));

        this.bnbHerodashPair.methods.getReserves()
          .call()
          .then((r2) => {
            const herodashPriceInBnb = (parseInt(r2.reserve1, 10) / parseInt(r2.reserve0, 10));
            const herodashPrice = parseInt((herodashPriceInBnb * bnbPriceInUsd * 100000), 10) / 100000;

            this.setState({
              herodashPrice,
              bnbPrice: bnbPriceInUsd,
              totalValueOfLiquidityPool: (parseInt(r2.reserve1, 10) * bnbPriceInUsd + parseInt(r2.reserve0, 10) * herodashPrice) / (10 ** 18),
              herodashAmountInLiquidityPool: parseInt(r2.reserve0, 10),
            }, () => {
              if (this.state.totalSupply) {
                this.setState({ marketCap: this.numberWithCommas(parseInt(parseInt(this.state.totalSupplyRaw, 10) * this.state.herodashPrice)) });
              }

              let tvl = 0;

              Promise.all([
                this.herodashMasterchief.methods.getPoolBalances().call(),
                this.bnbHerodashPair.methods.totalSupply().call(),
              ])
                .then((rs) => {
                  const stackedHerodash = rs[0][0];
                  tvl += parseInt((parseInt(stackedHerodash, 10) / (10 ** 18) * this.state.herodashPrice));

                  const stackedBnbHerodash = rs[0][1];
                  const totalBnbHerodashSupply = rs[1];

                  const lpvalue = this.state.totalValueOfLiquidityPool / parseInt(totalBnbHerodashSupply);

                  tvl += parseInt((parseInt(stackedBnbHerodash, 10) * lpvalue));

                  const totalWbnbStacked = rs[0][2];

                  tvl += parseInt((parseInt(totalWbnbStacked, 10) / (10 ** 18) * this.state.bnbPrice));

                  this.setState({
                    tvl: this.numberWithCommas(tvl),
                  })

                });

              // 3- Others CAKE, BUSD, BTCB
              const ps = [];
              for (let i = 0; i < constants.poolCount; i += 1) {
                ps.push(
                  this.herodashFire.methods.calculateTokenValueX100000(constants.pools[i].address, constants.pools[i].pairAddress, constants.masterChiefAddress).call(),
                );
              }


              Promise.all(ps)
                .then((rs) => {
                  for (let i = 0; i < constants.pools.length; i += 1) {
                    if (!Number.isNaN(parseInt(rs[i]))) {
                      tvl += parseInt((parseInt(rs[i]) * this.state.bnbPrice) / 100000);
                    }
                  }

                  this.setState({
                    tvl: this.numberWithCommas(tvl),
                  })
                });

            });

          });

      });

    if (loop) {
      setInterval(() => {

        // Get bnb price then herodash price
        this.bnbBusdPair.methods.getReserves()
          .call()
          .then((r) => {
            const bnbPriceInUsd = (parseInt(r.reserve1, 10) / parseInt(r.reserve0, 10));

            this.bnbHerodashPair.methods.getReserves()
              .call()
              .then((r2) => {
                const herodashPriceInBnb = (parseInt(r2.reserve1, 10) / parseInt(r2.reserve0, 10));
                const herodashPrice = parseInt((herodashPriceInBnb * bnbPriceInUsd * 100000), 10) / 100000;

                this.setState({
                  herodashPrice,
                  bnbPrice: bnbPriceInUsd,
                  totalValueOfLiquidityPool: (parseInt(r2.reserve0, 10) * bnbPriceInUsd + parseInt(r2.reserve1, 10) * herodashPrice) / (10 ** 18),
                  herodashAmountInLiquidityPool: parseInt(r2.reserve0, 10),
                }, () => {
                  if (this.state.totalSupply) {
                    this.setState({ marketCap: this.numberWithCommas(parseInt(parseInt(this.state.totalSupplyRaw, 10) * this.state.herodashPrice)) });
                  }

                  let tvl = 0;

                  Promise.all([
                    this.herodashMasterchief.methods.getPoolBalances().call(),
                    this.bnbHerodashPair.methods.totalSupply().call(),
                  ])
                    .then((rs) => {
                      const stackedHerodash = rs[0][0];
                      tvl += parseInt((parseInt(stackedHerodash, 10) / (10 ** 18) * this.state.herodashPrice));

                      const stackedBnbHerodash = rs[0][1];
                      const totalBnbHerodashSupply = rs[1];

                      const lpvalue = this.state.totalValueOfLiquidityPool / parseInt(totalBnbHerodashSupply);

                      tvl += parseInt((parseInt(stackedBnbHerodash, 10) * lpvalue));

                      const totalWbnbStacked = rs[0][2];

                      tvl += parseInt((parseInt(totalWbnbStacked, 10) / (10 ** 18) * this.state.bnbPrice));

                      this.setState({
                        tvl: this.numberWithCommas(tvl),
                      })

                    });

                  // 3- Others CAKE, BUSD, BTCB
                  const ps = [];
                  for (let i = 0; i < constants.poolCount; i += 1) {
                    ps.push(
                      this.herodashFire.methods.calculateTokenValueX100000(constants.pools[i].address, constants.pools[i].pairAddress, constants.masterChiefAddress).call(),
                    );
                  }


                  Promise.all(ps)
                    .then((rs) => {
                      for (let i = 0; i < constants.pools.length; i += 1) {
                        if (!Number.isNaN(parseInt(rs[i]))) {
                          tvl += parseInt((parseInt(rs[i]) * this.state.bnbPrice) / 100000);
                        }
                      }

                      this.setState({
                        tvl: this.numberWithCommas(tvl),
                      })
                    });

                });

              });

          });

      }, 60000);
    }

  }

  setPage = (newPage) => {
    this.setState({ page: newPage });

    if (this.state.account || this.state.ethereum) {
      //  this.loadUserStats();
      //  this.loadHerodashStats();
    }

  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getPageComponent = () => {

    const {
      page, herodashInWallet, totalSupply,
      transferTax, totalburned, account, herodashPrice,
      marketCap, herodashStacked, herodashToHarvest,
      tvl, herodashAmountInLiquidityPool,
      herodashValue,
    } = this.state;

    switch (page) {

      case "home": {
        return <Home
          herodashInWallet={herodashInWallet}
          totalSupply={totalSupply}
          transferTax={transferTax}
          totalburned={totalburned}
          account={account}
          herodashPrice={herodashPrice}
          marketCap={marketCap}
          connectWallet={this.connectWallet}
          herodashStacked={herodashStacked}
          herodashToHarvest={herodashToHarvest}
          herodashValue={herodashValue}
          tvl={tvl}
        />;
      }

      case "roadmap": {
        return <Roadmap />;
      }

      case "antidump": {
        return <Antidump />
      }

      case "buy": {
        return <Buy
          showError={this.showError}
          showSuccess={this.showSuccess}
          herodashSale={this.herodashSale}
          account={account}
          numberWithCommas={this.numberWithCommas}
          web3={this.web3}
          connectWallet={this.connectWallet}
          showTransaction={this.showTransaction}
        />
      }

      case "swap": {
        return <Swap
          showError={this.showError}
          showSuccess={this.showSuccess}
          pancakeRouter={this.pancakeRouter}
          bnb={this.bnb}
          herodash={this.herodash}
          account={account}
          numberWithCommas={this.numberWithCommas}
          web3={this.web3}
          herodashAmountInLiquidityPool={herodashAmountInLiquidityPool}
          connectWallet={this.connectWallet}
          showTransaction={this.showTransaction}
        />
      }

      case "liquidity": {
        return <AddLiquidity
          showError={this.showError}
          showSuccess={this.showSuccess}
          pancakeRouter={this.pancakeRouter}
          bnb={this.bnb}
          herodash={this.herodash}
          account={account}
          numberWithCommas={this.numberWithCommas}
          web3={this.web3}
          connectWallet={this.connectWallet}
          bnbHerodashPair={this.bnbHerodashPair}
          showTransaction={this.showTransaction}
        />
      }

      case "staking": {
        return <Staking
          showError={this.showError}
          showSuccess={this.showSuccess}
          bnb={this.bnb}
          herodash={this.herodash}
          account={account}
          numberWithCommas={this.numberWithCommas}
          web3={this.web3}
          connectWallet={this.connectWallet}
          bnbHerodashPair={this.bnbHerodashPair}
          herodashMasterchief={this.herodashMasterchief}
          totalValueOfLiquidityPool={this.state.totalValueOfLiquidityPool}
          herodashPrice={this.state.herodashPrice}
          bnbPrice={this.state.bnbPrice}
          showTransaction={this.showTransaction}
        />
      }

      case "audits": {
        return <Audits />
      }

      case "tools": {
        return <Tools
          showError={this.showError}
          showSuccess={this.showSuccess}
          account={account}
          connectWallet={this.connectWallet}
          herodashFire={this.herodashFire}
          web3={this.web3}
          showTransaction={this.showTransaction}
        />
      }

      case "deal": {
        return <Deal
          showError={this.showError}
          showSuccess={this.showSuccess}
          account={account}
          connectWallet={this.connectWallet}
          herodashFire={this.herodashFire}
          web3={this.web3}
          showTransaction={this.showTransaction}
        />
      }

      default: {
        return <Home
          herodashInWallet={herodashInWallet}
          totalSupply={totalSupply}
          transferTax={transferTax}
          totalburned={totalburned}
          account={account}
          herodashPrice={herodashPrice}
          marketCap={marketCap}
          connectWallet={this.connectWallet}
          herodashStacked={herodashStacked}
          herodashToHarvest={herodashToHarvest}
          herodashValue={herodashValue}
          tvl={tvl}
        />;
      }
    }
  }

  toggleSidebarState = () => this.setState({ fullSidebar: !this.state.fullSidebar, justLoaded: false });

  connectWallet = async () => {

    if (this.ethereum) {
      const accounts = await this.ethereum.request({ method: 'eth_requestAccounts' });
      if (accounts && accounts.length) {
        this.setState({ account: accounts[0] }, this.loadUserStats);
      } else if (this.ethereum.address) {
        this.setState({ account: window.ethereum.address }, this.loadUserStats);
      }
    } else {
      this.showError("Install metamask or use a Daap browser");
    }
  }

  render() {
    const {
      fullSidebar, page, account, showErrorModal, showSuccessModal,
      errorMessage, successMessage, transactionComplete,
      transactionHash, transactionSuccess, showTransactionSubmitedModal,
    } = this.state;
    return (
      <Router>
        <div className="bg-primary-light min-h-screen h-full">
          <Header fullSidebar={fullSidebar} toggleSidebarState={this.toggleSidebarState} connectWallet={this.connectWallet} account={account} />
          <Sidebar fullSidebar={fullSidebar} setPage={this.setPage} page={page} />
          <div className={`mt-16 ${fullSidebar ? 'ml-52' : 'ml-20'} h-full max-w-full overflow-x-hidden`}>
            {this.getPageComponent()}
          </div>
        </div>
        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-base font-fira text-red-500 break-all">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>
        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-base font-fira text-green-500 break-all">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* TRANSACTION MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showTransactionSubmitedModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showTransactionSubmitedModal: false, transactionHash: '' });
          }}
        >
          <div className="modal bg-white max-w-md rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <div className="bg-white flex justify-between p-6 rounded">
              <div className="text-lg font-fira font-medium text-green-500 break-all flex space-x-4">
                <p>
                  {transactionComplete && !transactionSuccess && <span className="material-icons text-red-600">error</span>}
                  {!transactionComplete && <span className="material-icons text-gray-600">schedule</span>}
                  {transactionComplete && transactionSuccess && <span className="material-icons text-green-500">check_circle</span>}
                </p>
                <div className="text-blue-600">
                  {transactionComplete && !transactionSuccess && <p className="text-red-500">Transaction failed</p>}
                  {!transactionComplete && <p className="text-gray-500">Transaction submited</p>}
                  {transactionComplete && transactionSuccess && <p className="text-green-500">Transaction completed</p>}

                  {transactionHash !== "" && <p className="mt-2"><a target="_blank" rel="noreferrer" href={`${constants.explorerLink}${transactionHash}`}>View on Bscscan</a></p>}
                </div>
              </div>
              <span
                onClick={() => {
                  this.setState({ showTransactionSubmitedModal: false, transactionHash: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </div>
          </div>
        </div>
      </Router>
    )
  }
}
