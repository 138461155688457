import React, { Component } from 'react'

export default class Antidump extends Component {
  render() {
    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">Anti-Dump</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">An anti-dump mecanism for a healthy grow</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-2xl w-full px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <p className="text-center text-white font-poppins font-semibold text-xl"><span className="material-icons-outlined text-other3 align-text-bottom">lock</span>  Anti-Dump</p>
            <p className="text-white font-poppins text-lg mt-4 font-semibold">To ensure to the token a healthy grow and avoid dumps, it implements an <span className="text-other1">Antidump</span> mechanism.</p>
            <p className="text-white font-poppins mt-4 text-lg font-semibold">This <span className="text-other1">Antidump</span> mechanism will only be active from <span className="text-other2">PHASE1</span> to <span className="text-other2">PHASE2</span>.</p>
            <p className="text-white font-poppins mt-4 text-lg font-semibold">This is how it works:</p>
            <p className="text-other3 font-poppins mt-4 text-lg font-semibold">Any transaction amount greatter than 2% of the total HERODASH liquidity reserve will revert. As the total liquidity reserve grows, this ratio will be reduced.</p>
          </div>
        </div>
      </div>
    )
  }
}
