import moment from 'moment';
import React, { Component } from 'react';
import HerodashLogo from '../assets/logo.svg';
import WBNBHerodash from '../assets/wbnb-herodash.svg';
import WBNB from '../assets/wbnb.png';
import constants from '../constants';
import BUSD from '../assets/busd.png';
import CAKE from '../assets/cake.png';
import BTCB from '../assets/btcb.png';

export default class Staking extends Component {

  state = {
    amount1: 0,
    amount2: 0,
    amount3: 0,
    approveBnb: true,
    approveHerodash: true,
    approveBnbHerodashLp: true,
    allowedBnb: new this.props.web3.utils.BN('0'),
    allowedHerodash: new this.props.web3.utils.BN('0'),
    allowedBnbHerodashLp: new this.props.web3.utils.BN('0'),
    account: this.props.account,
    bnbStacked: 0,
    lpStacked: 0,
    herodashStaked: 0,
    stacked: [],
    balances: [],
    pending: [],
    harvestOn: [],
    herodashPerBlock: 672,
    herodashPerBlock1: (672 * 24) / 100,
    herodashPerBlock2: (672 * 36) / 100,
    herodashPerBlock3: (672 * 10) / 100,
    herodashPerBlock4: (672 * 10) / 100,
    herodashPerBlock5: (672 * 10) / 100,
    herodashPerBlock6: (672 * 10) / 100,
    apr1: parseInt(""),
    apr2: parseInt(""),
    apr3: parseInt(""),
    apr4: parseInt(""),
    apr5: parseInt(""),
    apr6: parseInt(""),
    phase: 0,
  };

  componentDidMount() {
    this.checkAllowance();
    this.loadbalance();
    this.loadAPR();
  }

  componentDidUpdate() {
    if (this.state.account !== this.props.account) {
      this.setState({
        account: this.props.account, allowedBnb: new this.props.web3.utils.BN('0'),
        allowedHerodash: new this.props.web3.utils.BN('0'),
        allowedBnbHerodashLp: new this.props.web3.utils.BN('0'),
        bnbStacked: 0,
        lpStacked: 0,
        herodashStaked: 0,
        stacked: [],
        balances: [],
        pending: [],
        harvestOn: [],
      },
        () => {
          this.checkAllowance();
          this.loadbalance(false);
        });
    }
  }

  loadbalance = (loop = true) => {
    if (!this.state.account) {
      return;
    }
    Promise.all([
      this.props.herodashMasterchief.methods.getUserInfos(this.state.account).call(),
    ])
      .then((rs) => {
        const stacked = rs[0][0].map((s) => parseInt((this.props.web3.utils.fromWei(s, 'ether') * 100000)) / 100000);
        const pending = rs[0][1].map((p) => parseInt((this.props.web3.utils.fromWei(p, 'ether') * 100000)) / 100000);
        const harvestOn = rs[0][2].map((h) => parseInt(h));
        const balances = rs[0][3].map((b) => parseInt((this.props.web3.utils.fromWei(b, 'ether') * 100000)) / 100000);

        this.setState({ stacked, pending, harvestOn, balances });

      });

    if (loop) {
      setInterval(() => {
        Promise.all([
          this.props.herodashMasterchief.methods.getUserInfos(this.state.account).call(),
        ])
          .then((rs) => {
            const stacked = rs[0][0].map((s) => parseInt((this.props.web3.utils.fromWei(s, 'ether') * 100000)) / 100000);
            const pending = rs[0][1].map((p) => parseInt((this.props.web3.utils.fromWei(p, 'ether') * 100000)) / 100000);
            const harvestOn = rs[0][2].map((h) => parseInt(h));
            const balances = rs[0][3].map((b) => parseInt((this.props.web3.utils.fromWei(b, 'ether') * 100000)) / 100000);

            this.setState({ stacked, pending, harvestOn, balances });

          });
      }, 30000);
    }
  }

  loadAPR = () => {
    if (this.state.phase === 0 || this.state.phase === 3) return;

    Promise.all([
      this.props.herodashMasterchief.methods.getPoolBalances().call(),
      this.props.bnbHerodashPair.methods.totalSupply().call(),
    ])
      .then((rs) => {

        const stackedHerodash = rs[0][0];

        const currentValue1 = 1 + this.props.web3.utils.fromWei(stackedHerodash, 'ether') * this.props.herodashPrice;
        const projectedValue1 = this.state.herodashPerBlock1 * (31536000 / 3) * this.props.herodashPrice;

        const currentValue2 = 1 + (this.props.web3.utils.fromWei(rs[0][1], 'ether') * this.props.totalValueOfLiquidityPool) / this.props.web3.utils.fromWei(rs[1], 'ether');
        const projectedValue2 = this.state.herodashPerBlock2 * (31536000 / 3) * this.props.herodashPrice;

        const currentValue3 = 1 + (this.props.web3.utils.fromWei(rs[0][2], 'ether') * this.props.bnbPrice);
        const projectedValue3 = this.state.herodashPerBlock3 * (31536000 / 3) * this.props.herodashPrice;

        this.setState({
          apr1: this.props.numberWithCommas(parseInt((projectedValue1 / currentValue1) * 100)),
          apr2: this.props.numberWithCommas(parseInt((projectedValue2 / currentValue2) * 100)),
          apr3: this.props.numberWithCommas(parseInt((projectedValue3 / currentValue3) * 100)),
        });
      });
  }

  checkAllowance = (pos = 10) => {
    if (!this.state.account) return;

    if (pos === 10 || pos === 3) {
      if (this.state.allowedBnb.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amount3}`, 'ether')).cmp(this.state.allowedBnb) === 1) {
          this.setState({ approveBnb: true });
        } else {
          this.setState({ approveBnb: false });
        }
      } else {
        this.props.bnb.methods.allowance(this.state.account, constants.masterChiefAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);

            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amount3}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedBnb: allowance, approveBnb: true });

            } else {

              this.setState({ allowedBnb: allowance, approveBnb: false });
            }
          });
      }
    }



    if (pos === 10 || pos === 1) {
      if (this.state.allowedHerodash.cmp(new this.props.web3.utils.BN('0')) === 1) {

        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amount1}`, 'ether')).cmp(this.state.allowedHerodash) === 1) {
          this.setState({ approveHerodash: true });
        } else {
          this.setState({ approveHerodash: false });
        }
      } else {
        this.props.herodash.methods.allowance(this.state.account, constants.masterChiefAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);

            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amount2}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedHerodash: allowance, approveHerodash: true });
            } else {
              this.setState({ allowedHerodash: allowance, approveHerodash: false });
            }
          });
      }
    }



    if (pos === 10 || pos === 2) {
      if (this.state.allowedBnbHerodashLp.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amount2}`, 'ether')).cmp(this.state.allowedBnbHerodashLp) === 1) {
          this.setState({ approveBnbHerodashLp: true });
        } else {
          this.setState({ approveBnbHerodashLp: false });
        }
      } else {
        this.props.bnbHerodashPair.methods.allowance(this.state.account, constants.masterChiefAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);

            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amount2}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedBnbHerodashLp: allowance, approveBnbHerodashLp: true });
            } else {
              this.setState({ allowedBnbHerodashLp: allowance, approveBnbHerodashLp: false });
            }
          });
      }
    }

  }

  approveBnb = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.bnb.methods.approve(constants.masterChiefAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveBnb: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  approveHerodash = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.herodash.methods.approve(constants.masterChiefAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveHerodash: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  approveBnbHerodash = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.bnbHerodashPair.methods.approve(constants.masterChiefAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveBnbHerodashLp: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  deposit = (pos) => {
    const { amount1, amount2, amount3, account } = this.state;

    let amountToDeposit;

    switch (pos) {
      case 1: {
        amountToDeposit = this.props.web3.utils.toWei(`${amount1}`, 'ether');
        break;
      }
      case 2: {
        amountToDeposit = this.props.web3.utils.toWei(`${amount2}`, 'ether');
        break;
      }
      case 3: {
        amountToDeposit = this.props.web3.utils.toWei(`${amount3}`, 'ether');
        break;
      }
      default: { }
    }

    if (account) {

      this.props.herodashMasterchief.methods.deposit(
        (pos - 1),
        amountToDeposit,
      ).send({ from: account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance(false);
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  withdraw = (pos) => {
    const { amount1, amount2, amount3, account } = this.state;

    let amountToWithdraw;

    switch (pos) {
      case 1: {
        amountToWithdraw = this.props.web3.utils.toWei(`${amount1}`, 'ether');
        break;
      }
      case 2: {
        amountToWithdraw = this.props.web3.utils.toWei(`${amount2}`, 'ether');
        break;
      }
      case 3: {
        amountToWithdraw = this.props.web3.utils.toWei(`${amount3}`, 'ether');
        break;
      }
      default: { }
    }

    console.log(amountToWithdraw.toString());

    if (account) {

      this.props.herodashMasterchief.methods.withdraw(
        (pos - 1),
        amountToWithdraw,
      ).send({ from: account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance(false);
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  harvest = (pos) => {
    const { account } = this.state;

    if (account) {

      this.props.herodashMasterchief.methods.withdraw(
        (pos - 1),
        0,
      ).send({ from: account })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance(false);
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  render() {

    const {
      account, approveBnb, approveBnbHerodashLp,
      approveHerodash, amount1, amount2, amount3, stacked, pending, balances, harvestOn,
      apr2, apr1, apr3, herodashPerBlock1, herodashPerBlock2, herodashPerBlock3,
      apr4, apr5, apr6, herodashPerBlock4, herodashPerBlock5, herodashPerBlock6,
    } = this.state;

    return (
      <div className="h-full pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">Staking</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">Stake tokens to earn HERODASH</p>
        </div>
        <div className="bg-primary-light pb-14 max-w-7xl mx-auto">
          <div className="px-2 grid md:grid-cols-2 xl:grid-cols-3 py-8 gap-6 space-y-2 md:space-y-0 max-w-sm md:max-w-3xl mx-auto lg:max-w-max">
            <div className="bg-primary max-w-sm px-4 md:px-6 py-4 rounded-xl shadow-md">
              <div className="flex justify-between">
                <p className="w-16 h-16 bg-primary-light rounded-full">
                  <img alt="herodash logo" src={HerodashLogo} />
                </p>
                <p className="text-lg font-poppins font-bold text-white self-center"><span className="material-icons-outlined text-other3 align-text-bottom">layers</span> HERODASH</p>
              </div>
              <div className="mt-8">
                <ul>
                  <li className="flex justify-between">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH Per Block</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{herodashPerBlock1}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">APR</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{apr1}%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Earn</p>
                    <p className="text-other3 text-lg font-poppins font-bold">HERODASH</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Deposit Fee</p>
                    <p className="text-other3 text-lg font-poppins font-bold">0%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Harvest Lockup</p>
                    <p className="text-other3 text-lg font-poppins font-bold">4 Hours</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH STACKED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{stacked[0]}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH EARNED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{pending[0]}</p>
                  </li>
                </ul>
              </div>
              {(account !== null && !approveHerodash) && <div>
                <div className="flex justify-between mt-4 space-x-4">
                  {/* <button onClick={this.compound} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">COMPOUND {Date.now() < harvestOn[0] * 1000 ? moment(new Date(harvestOn[0] * 1000)).fromNow() : ''}</button> */}
                  <button onClick={() => this.harvest(1)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">HARVEST {Date.now() < harvestOn[0] * 1000 ? moment(new Date(harvestOn[0] * 1000)).fromNow() : ''}</button>
                </div>
                <div className="mt-4">
                  <p className="text-white font-poppins">Deposit or Withdraw</p>
                  <input
                    className="text-lg font-poppins py-2 w-full outline-none px-3 text-other3 mt-1 rounded-md"
                    type="number"
                    value={Number(amount1).toString()}
                    onChange={(e) => {
                      this.setState({ amount1: parseInt(e.target.value * 100000) / 100000 }, () => this.checkAllowance(1));
                    }}
                  />
                  <label className="text-other2 font-poppins">You have {balances[0]} HERODASH</label>
                  <div className="flex justify-between mt-2 space-x-4">
                    <button onClick={() => this.deposit(1)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">DEPOSIT</button>
                    <button onClick={() => this.withdraw(1)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">WITHDRAW</button>
                  </div>
                </div>
              </div>}
              {account === null && <div className="mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect Wallet</button>
              </div>}
              {(account !== null && approveHerodash) && <div className="mt-4">
                <button onClick={this.approveHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve HERODASH</button>
              </div>}
            </div>
            <div className="bg-primary max-w-sm px-4 md:px-6 py-4 rounded-xl shadow-md">
              <div className="flex justify-between space-x-3">
                <p className="w-24">
                  <img alt="herodash logo" src={WBNBHerodash} />
                </p>
                <p className="text-lg font-poppins font-bold text-white self-center"><span className="material-icons-outlined text-other3 align-text-bottom">layers</span> WBNB-HERODASH</p>
              </div>
              <div className="mt-8">
                <ul>
                  <li className="flex justify-between">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH Per Block</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{herodashPerBlock2}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">APR</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{apr2}%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Earn</p>
                    <p className="text-other3 text-lg font-poppins font-bold">HERODASH</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Deposit Fee</p>
                    <p className="text-other3 text-lg font-poppins font-bold">0%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Harvest Lockup</p>
                    <p className="text-other3 text-lg font-poppins font-bold">4 Hours</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">WBNB-HERODASH LP STACKED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{stacked[1]}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH EARNED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{pending[1]}</p>
                  </li>
                </ul>
              </div>
              {(account !== null && !approveBnbHerodashLp) && <div>
                <div className="flex justify-between mt-4">
                  <button onClick={() => this.harvest(2)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">HARVEST {Date.now() < harvestOn[1] * 1000 ? moment(new Date(harvestOn[1] * 1000)).fromNow() : ''}</button>
                </div>
                <div className="mt-4">
                  <p className="text-white font-poppins">Deposit or Withdraw</p>
                  <input
                    type="number"
                    value={Number(amount2).toString()}
                    onChange={(e) => {
                      this.setState({ amount2: parseInt(e.target.value * 100000) / 100000 }, () => this.checkAllowance(2));
                    }}
                    className="text-lg font-poppins py-2 w-full outline-none px-3 text-other3 mt-1 rounded-md" />
                  <label className="text-other2 font-poppins">You have {balances[1]} WBNB-HERODASH LP</label>
                  <div className="flex justify-between mt-2 space-x-4">
                    <button onClick={() => this.deposit(2)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">DEPOSIT</button>
                    <button onClick={() => this.withdraw(2)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">WITHDRAW</button>
                  </div>
                </div>
              </div>}
              {account === null && <div className="mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect Wallet</button>
              </div>}
              {(account !== null && approveBnbHerodashLp) && <div className="mt-4">
                <button onClick={this.approveBnbHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve WBNB-HERODASH LP</button>
              </div>}
            </div>
            <div className="bg-primary max-w-sm px-4 md:px-6 py-4 rounded-xl shadow-md">
              <div className="flex justify-between">
                <p className="w-16 bg-primary-light rounded-full p-1">
                  <img alt="herodash logo" src={WBNB} />
                </p>
                <p className="text-lg font-poppins font-bold text-white self-center"><span className="material-icons-outlined text-other3 align-text-bottom">layers</span> WBNB</p>
              </div>
              <div className="mt-8">
                <ul>
                  <li className="flex justify-between">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH Per Block</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{herodashPerBlock3}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">APR</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{apr3}%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Earn</p>
                    <p className="text-other3 text-lg font-poppins font-bold">HERODASH</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Deposit Fee</p>
                    <p className="text-other3 text-lg font-poppins font-bold">2%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Harvest Lockup</p>
                    <p className="text-other3 text-lg font-poppins font-bold">4 Hours</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">WBNB STACKED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{stacked[2]}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH EARNED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{pending[2]}</p>
                  </li>
                </ul>
              </div>
              {(account !== null && !approveBnb) && <div>
                <div className="flex justify-between mt-4">
                  <button onClick={() => this.harvest(3)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">HARVEST {Date.now() < harvestOn[2] * 1000 ? moment(new Date(harvestOn[2] * 1000)).fromNow() : ''}</button>
                </div>
                <div className="mt-4">
                  <p className="text-white font-poppins">Deposit or Withdraw</p>
                  <input
                    type="number"
                    value={Number(amount3).toString()}
                    onChange={(e) => {
                      this.setState({ amount3: parseInt(e.target.value * 100000) / 100000 }, () => this.checkAllowance(3));
                    }}
                    className="text-lg font-poppins py-2 w-full outline-none px-3 text-other3 mt-1 rounded-md" />
                  <label className="text-other2 font-poppins">You have {balances[2]} WBNB</label>
                  <div className="flex justify-between mt-2 space-x-4">
                    <button onClick={() => this.deposit(3)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">DEPOSIT</button>
                    <button onClick={() => this.withdraw(3)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">WITHDRAW</button>
                  </div>
                </div>
              </div>}
              {account === null && <div className="mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect Wallet</button>
              </div>}
              {(account !== null && approveBnb) && <div className="mt-4">
                <button onClick={this.approveBnb} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve WBNB</button>
              </div>}
            </div>
          </div>

          <div className="px-2 grid md:grid-cols-2 xl:grid-cols-3 py-8 gap-6 space-y-2 md:space-y-0 max-w-sm md:max-w-3xl mx-auto lg:max-w-max">
            <div className="bg-primary max-w-sm px-4 md:px-6 py-4 rounded-xl shadow-md">
              <div className="flex justify-between space-x-6">
                <p className="w-16 h-16 bg-primary-light rounded-full p-1">
                  <img alt="cake logo" src={CAKE} />
                </p>
                <p className="text-lg font-poppins font-bold text-white self-center"><span className="material-icons-outlined text-other3 align-text-bottom">layers</span>PANCAKESWAP CAKE</p>
              </div>
              <div className="mt-8">
                <ul>
                  <li className="flex justify-between">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH Per Block</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{herodashPerBlock4}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">APR</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{apr4}%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Earn</p>
                    <p className="text-other3 text-lg font-poppins font-bold">HERODASH</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Deposit Fee</p>
                    <p className="text-other3 text-lg font-poppins font-bold">2%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Harvest Lockup</p>
                    <p className="text-other3 text-lg font-poppins font-bold">4 Hours</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">CAKE STACKED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{stacked[0]}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH EARNED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{pending[0]}</p>
                  </li>
                </ul>
              </div>
              {(account !== null && !approveHerodash) && <div>
                <div className="flex justify-between mt-4 space-x-4">
                  {/* <button onClick={this.compound} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">COMPOUND {Date.now() < harvestOn[0] * 1000 ? moment(new Date(harvestOn[0] * 1000)).fromNow() : ''}</button> */}
                  <button onClick={() => this.harvest(1)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">HARVEST {Date.now() < harvestOn[0] * 1000 ? moment(new Date(harvestOn[0] * 1000)).fromNow() : ''}</button>
                </div>
                <div className="mt-4">
                  <p className="text-white font-poppins">Deposit or Withdraw</p>
                  <input
                    className="text-lg font-poppins py-2 w-full outline-none px-3 text-other3 mt-1 rounded-md"
                    type="number"
                    value={Number(amount1).toString()}
                    onChange={(e) => {
                      this.setState({ amount1: parseInt(e.target.value * 100000) / 100000 }, () => this.checkAllowance(1));
                    }}
                  />
                  <label className="text-other2 font-poppins">You have {balances[0]} HERODASH</label>
                  <div className="flex justify-between mt-2 space-x-4">
                    <button onClick={() => this.deposit(1)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">DEPOSIT</button>
                    <button onClick={() => this.withdraw(1)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">WITHDRAW</button>
                  </div>
                </div>
              </div>}
              {account === null && <div className="mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect Wallet</button>
              </div>}
              {(account !== null && approveHerodash) && <div className="mt-4">
                <button onClick={this.approveHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve CAKE</button>
              </div>}
            </div>
            <div className="bg-primary max-w-sm px-4 md:px-6 py-4 rounded-xl shadow-md">
              <div className="flex justify-between space-x-3">
              <p className="w-16 bg-primary-light rounded-full p-1">
                  <img alt="busd logo" src={BUSD} />
                </p>
                <p className="text-lg font-poppins font-bold text-white self-center"><span className="material-icons-outlined text-other3 align-text-bottom">layers</span> BUSD</p>
              </div>
              <div className="mt-8">
                <ul>
                  <li className="flex justify-between">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH Per Block</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{herodashPerBlock5}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">APR</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{apr5}%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Earn</p>
                    <p className="text-other3 text-lg font-poppins font-bold">HERODASH</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Deposit Fee</p>
                    <p className="text-other3 text-lg font-poppins font-bold">2%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Harvest Lockup</p>
                    <p className="text-other3 text-lg font-poppins font-bold">4 Hours</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">BUSD STACKED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{stacked[1]}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH EARNED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{pending[1]}</p>
                  </li>
                </ul>
              </div>
              {(account !== null && !approveBnbHerodashLp) && <div>
                <div className="flex justify-between mt-4">
                  <button onClick={() => this.harvest(2)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">HARVEST {Date.now() < harvestOn[1] * 1000 ? moment(new Date(harvestOn[1] * 1000)).fromNow() : ''}</button>
                </div>
                <div className="mt-4">
                  <p className="text-white font-poppins">Deposit or Withdraw</p>
                  <input
                    type="number"
                    value={Number(amount2).toString()}
                    onChange={(e) => {
                      this.setState({ amount2: parseInt(e.target.value * 100000) / 100000 }, () => this.checkAllowance(2));
                    }}
                    className="text-lg font-poppins py-2 w-full outline-none px-3 text-other3 mt-1 rounded-md" />
                  <label className="text-other2 font-poppins">You have {balances[1]} WBNB-HERODASH LP</label>
                  <div className="flex justify-between mt-2 space-x-4">
                    <button onClick={() => this.deposit(2)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">DEPOSIT</button>
                    <button onClick={() => this.withdraw(2)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">WITHDRAW</button>
                  </div>
                </div>
              </div>}
              {account === null && <div className="mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect Wallet</button>
              </div>}
              {(account !== null && approveBnbHerodashLp) && <div className="mt-4">
                <button onClick={this.approveBnbHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve BUSD</button>
              </div>}
            </div>
            <div className="bg-primary max-w-sm px-4 md:px-6 py-4 rounded-xl shadow-md">
              <div className="flex justify-between">
                <p className="w-16 bg-primary-light rounded-full p-1">
                  <img alt="herodash logo" src={BTCB} />
                </p>
                <p className="text-lg font-poppins font-bold text-white self-center"><span className="material-icons-outlined text-other3 align-text-bottom">layers</span> BTCB</p>
              </div>
              <div className="mt-8">
                <ul>
                  <li className="flex justify-between">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH Per Block</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{herodashPerBlock6}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">APR</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{apr6}%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Earn</p>
                    <p className="text-other3 text-lg font-poppins font-bold">HERODASH</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Deposit Fee</p>
                    <p className="text-other3 text-lg font-poppins font-bold">2%</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">Harvest Lockup</p>
                    <p className="text-other3 text-lg font-poppins font-bold">4 Hours</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">WBNB STACKED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{stacked[2]}</p>
                  </li>
                  <li className="flex justify-between mt-3">
                    <p className="text-white text-lg font-poppins font-semibold">HERODASH EARNED</p>
                    <p className="text-other3 text-lg font-poppins font-bold">{pending[2]}</p>
                  </li>
                </ul>
              </div>
              {(account !== null && !approveBnb) && <div>
                <div className="flex justify-between mt-4">
                  <button onClick={() => this.harvest(3)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75 text-xs">HARVEST {Date.now() < harvestOn[2] * 1000 ? moment(new Date(harvestOn[2] * 1000)).fromNow() : ''}</button>
                </div>
                <div className="mt-4">
                  <p className="text-white font-poppins">Deposit or Withdraw</p>
                  <input
                    type="number"
                    value={Number(amount3).toString()}
                    onChange={(e) => {
                      this.setState({ amount3: parseInt(e.target.value * 100000) / 100000 }, () => this.checkAllowance(3));
                    }}
                    className="text-lg font-poppins py-2 w-full outline-none px-3 text-other3 mt-1 rounded-md" />
                  <label className="text-other2 font-poppins">You have {balances[2]} WBNB</label>
                  <div className="flex justify-between mt-2 space-x-4">
                    <button onClick={() => this.deposit(3)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">DEPOSIT</button>
                    <button onClick={() => this.withdraw(3)} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">WITHDRAW</button>
                  </div>
                </div>
              </div>}
              {account === null && <div className="mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect Wallet</button>
              </div>}
              {(account !== null && approveBnb) && <div className="mt-4">
                <button onClick={this.approveBnb} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve BTCB</button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
