import React, { Component } from 'react'
import constants from '../constants';

export default class Swap extends Component {

  state = {
    amountIn: 0,
    amountOut: 0,
    sell: false,
    herodashAmountInLiquidityPool: this.props.herodashAmountInLiquidityPool,
    approveBnb: true,
    approveHerodash: true,
    allowedBnb: new this.props.web3.utils.BN('0'),
    allowedHerodash: new this.props.web3.utils.BN('0'),
    account: this.props.account,
    bnbBalance: 0,
    herodashBalance: 0,
    maxOfSupply: this.props.herodashAmountInLiquidityPool ? parseInt((this.props.herodashAmountInLiquidityPool * 0.02) / (10 ** 18)) : 0,
  };

  pancakeRouter = this.props.pancakeRouter;

  componentDidMount() {
    this.checkAllowance();
    this.loadbalance();
  }

  loadbalance = () => {
    if (!this.state.account) {
      return;
    }
    Promise.all([
      this.props.herodash.methods.balanceOf(this.state.account).call(),
      this.props.web3.eth.getBalance(this.state.account),
    ])
      .then((rs) => {
        this.setState({ bnbBalance: parseInt(parseInt(rs[1]) / 10 ** 13) / 100000, herodashBalance: parseInt(parseInt(rs[0]) / 10 ** 13) / 100000, });
      });
  }

  getEquivalentAmount = (newAmount, isIn) => {
    newAmount = parseInt(newAmount * 10000) / 10000;

    if (isIn) {
      this.setState({ amountIn: newAmount });

      if (newAmount <= 0) {
        return this.setState({ amountOut: 0 });
      }

      let path;

      if (this.state.sell) {
        path = [constants.herodashAddress, constants.wbnbAddress];
      } else {
        path = [constants.wbnbAddress, constants.herodashAddress];
      }

      this.pancakeRouter.methods.getAmountsOut(
        this.props.web3.utils.toWei(`${newAmount}`, 'ether'),
        path,
      ).call()
        .then((res) => {
          this.setState({ amountOut: parseInt((parseInt(res[1]) * 0.94) / 10 ** 14) / 10000 });
        });


    } else {
      this.setState({ amountOut: newAmount });

      if (newAmount <= 0) {
        return this.setState({ amountIn: 0 });
      }

      let path;

      if (!this.state.sell) {
        path = [constants.herodashAddress, constants.wbnbAddress];
      } else {
        path = [constants.wbnbAddress, constants.herodashAddress];
      }

      this.pancakeRouter.methods.getAmountsOut(
        this.props.web3.utils.toWei(`${newAmount}`, 'ether'),
        path,
      ).call()
        .then((res) => {
          this.setState({ amountIn: parseInt((parseInt(res[1]) * 1.06) / 10 ** 14) / 10000 });
        });
    }
    this.checkAllowance();
  }

  componentDidUpdate() {
    if (this.state.account !== this.props.account) {
      this.setState({ account: this.props.account, allowedBnb: new this.props.web3.utils.BN('0'), allowedHerodash: new this.props.web3.utils.BN('0') },
        () => {
          this.checkAllowance();
          this.loadbalance();
        });
    }

    if (this.props.herodashAmountInLiquidityPool !== this.state.herodashAmountInLiquidityPool) {
      this.setState({ herodashAmountInLiquidityPool: this.props.herodashAmountInLiquidityPool, maxOfSupply: parseInt((this.props.herodashAmountInLiquidityPool * 0.05) / (10 ** 18)) });
    }
  }

  swap = () => {
    if (this.state.account) {

      const { sell, amountIn, amountOut, account, maxOfSupply } = this.state;

      if (sell) {
        if (amountIn > maxOfSupply) {
          return this.props.showError(`You can't sell more than 5% of the total Liquidity Supply (${maxOfSupply})`);
        }


        this.pancakeRouter.methods.swapExactTokensForETH(
          this.props.web3.utils.toWei(`${amountIn}`, 'ether'),
          this.props.web3.utils.toWei(`${amountOut}`, 'ether'),
          [constants.herodashAddress, constants.wbnbAddress],
          account,
          Date.now() + 1000 * 60 * 5,
        ).send({ from: account })
          .once('transactionHash', (hash) => {
            this.props.showTransaction(hash, false, false, 10);
          })
          .then((x) => {
            this.props.showTransaction(x.transactionHash, true, true, 10);
            this.loadbalance();
          })
          .catch((e) => {
            console.log(e);
            this.props.showTransaction("", true, false, 10);
          });


      } else {

        this.pancakeRouter.methods.swapExactETHForTokens(
          this.props.web3.utils.toWei(`${amountOut}`, 'ether'),
          [constants.wbnbAddress, constants.herodashAddress],
          account,
          Date.now() + 1000 * 60 * 5,
        ).send({ from: account, value: this.props.web3.utils.toWei(`${amountIn}`, 'ether') })
          .once('transactionHash', (hash) => {
            this.props.showTransaction(hash, false, false, 10);
          })
          .then((x) => {
            this.props.showTransaction(x.transactionHash, true, true, 10);
            this.loadbalance();
          })
          .catch((e) => {
            console.log(e);
            this.props.showTransaction("", true, false, 10);
          });

      }

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  swapAlt = () => {
    if (this.state.account) {

      const { sell, amountIn, amountOut, account, maxOfSupply } = this.state;

      if (sell) {
        if (amountIn > maxOfSupply) {
          return this.props.showError(`You can't sell more than 5% of the total Liquidity Supply (${maxOfSupply})`);
        }


        this.pancakeRouter.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
          this.props.web3.utils.toWei(`${amountIn}`, 'ether'),
          this.props.web3.utils.toWei(`${amountOut * 0.4}`, 'ether'),
          [constants.herodashAddress, constants.wbnbAddress],
          account,
          Date.now() + 1000 * 60 * 5,
        ).send({ from: account })
          .once('transactionHash', (hash) => {
            this.props.showTransaction(hash, false, false, 10);
          })
          .then((x) => {
            this.props.showTransaction(x.transactionHash, true, true, 10);
            this.loadbalance();
          })
          .catch((e) => {
            console.log(e);
            this.props.showTransaction("", true, false, 10);
          });


      } else {

        this.pancakeRouter.methods.swapExactETHForTokens(
          this.props.web3.utils.toWei(`${amountOut}`, 'ether'),
          [constants.wbnbAddress, constants.herodashAddress],
          account,
          Date.now() + 1000 * 60 * 5,
        ).send({ from: account, value: this.props.web3.utils.toWei(`${amountIn}`, 'ether') })
          .once('transactionHash', (hash) => {
            this.props.showTransaction(hash, false, false, 10);
          })
          .then((x) => {
            this.props.showTransaction(x.transactionHash, true, true, 10);
            this.loadbalance();
          })
          .catch((e) => {
            console.log(e);
            this.props.showTransaction("", true, false, 10);
          });

      }

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  checkAllowance = () => {

    if (!this.state.sell) {
      this.setState({ approveBnb: true, approveHerodash: false });

      if (!this.state.account) return;

      if (this.state.allowedBnb.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn}`, 'ether')).cmp(this.state.allowedBnb) === 1) {
          this.setState({ approveBnb: true });
        } else {
          this.setState({ approveBnb: false });
        }
      } else {
        this.props.bnb.methods.allowance(this.state.account, constants.pancakeRouterAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);


            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedBnb: allowance, approveBnb: true });
            } else {
              this.setState({ allowedBnb: allowance, approveBnb: false });
            }
          });
      }

    } else {
      this.setState({ approveHerodash: true, approveBnb: false });

      if (!this.state.account) return;

      if (this.state.allowedHerodash.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn}`, 'ether')).cmp(this.state.allowedHerodash) === 1) {
          this.setState({ approveHerodash: true });
        } else {
          this.setState({ approveHerodash: false });
        }
      } else {
        this.props.herodash.methods.allowance(this.state.account, constants.pancakeRouterAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);

            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.amountIn}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedHerodash: allowance, approveHerodash: true });
            } else {
              this.setState({ allowedHerodash: allowance, approveHerodash: false });
            }
          });
      }

    }
  }

  approveBnb = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.bnb.methods.approve(constants.pancakeRouterAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveBnb: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  approveHerodash = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    this.props.herodash.methods.approve(constants.pancakeRouterAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveHerodash: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  render() {
    const {
      amountIn, amountOut, sell,
      approveBnb, approveHerodash, account, bnbBalance, herodashBalance,
      maxOfSupply,
    } = this.state;

    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">SWAP HERODASH</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">Swap BNB for HERODASH or HERODASH for BNB</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <p className='text-white font-poppins text-lg font-semibold'><span className="material-icons-outlined text-other3 align-text-bottom">swap_horiz</span>  SWAP {sell ? 'HERODASH for BNB' : 'BNB for HERODASH'}</p>
            {sell && <p className="bg-primary-light text-other1 py-1 px-2 font-poppins mt-1">
                Reminder: Every $HEDA transaction over 2% of total liquidity ({maxOfSupply} Herodash) will revert.
              </p>}
            <div className="mt-3">
              <label className="text-other2 font-poppins font-semibold">{sell ? 'HERODASH' : 'BNB'} (You have {sell ? herodashBalance : bnbBalance})</label>
              <input value={Number(amountIn).toString()} onChange={(e) => this.getEquivalentAmount(e.target.value, true)} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
            </div>
            <p className="text-center mt-2">
              <span
                onClick={() => {
                  this.setState({ sell: !sell, amountIn: 0, amountOut: 0 }, this.checkAllowance);
                }}
                className="material-icons-outlined bg-primary-light text-white rounded-full p-1 cursor-pointer">swap_vert</span>
            </p>
            <div className="mt-2">
              <label className="text-other2 font-poppins font-semibold">{sell ? 'BNB' : 'HERODASH'} (You have {sell ? bnbBalance : herodashBalance})</label>
              <input value={Number(amountOut).toString()} onChange={(e) => this.getEquivalentAmount(e.target.value, false)} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
            </div>
            {account !== null && <div>
              {(!approveBnb && !approveHerodash) && <div className="mt-6">
                <button onClick={this.swap} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">SWAP</button>
              </div>}
              {approveBnb && <div className="mt-6">
                <button onClick={this.approveBnb} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve BNB (For PancakeSwap)</button>
              </div>}
              {approveHerodash && <div className="mt-6">
                <button onClick={this.approveHerodash} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve HERODASH (For PancakeSwap)</button>
              </div>}
            </div>}
            {account === null && <div className="mt-6">
              <button onClick={this.props.connectWallet} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Connect wallet</button>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}
