import React, { Component } from 'react'

export default class Buy extends Component {

  state = {
    weiRaised: 0,
    rate: 0,
    bnbRemaining: "500",
    completed: 0,
    bnbAmount: 0.5,
    cap: 500000000000000000000,
    bnbBalance: 0,
    round: 1,
  };

  herodashSale = this.props.herodashSale;

  componentDidMount() {
    this.loadStats();
    this.loadbalance();
  }

  componentDidUpdate() {
    if (this.state.account !== this.props.account) {
      this.setState({
        account: this.props.account,
        bnbBalance: 0,
      },
        () => {
          this.loadbalance();
        });
    }
  }

  loadbalance = () => {
    if (!this.state.account) {
      return;
    }
    Promise.all([
      this.props.web3.eth.getBalance(this.state.account),
    ])
      .then((rs) => {
        this.setState({ bnbBalance: parseInt(parseInt(rs[0]) / 10 ** 13) / 100000 });
      });
  }

  loadStats = (loop = true) => {
    Promise.all([
      this.herodashSale.methods.rate().call(),
      this.herodashSale.methods.weiRaised().call(),
    ])
      .then((rs) => {
        this.setState({
          weiRaised: rs[1],
          rate: rs[0],
          completed: parseInt(rs[1]) > 500*10**18 ? parseInt(((parseInt(rs[1]) - 500*10**18) / this.state.cap) * 10000) / 100 : parseInt(((parseInt(rs[1])) / this.state.cap) * 10000) / 100,
          bnbRemaining: parseInt(rs[1]) > 500*10**18 ? parseInt(((this.state.cap - parseInt(rs[1]) - 500*10**18) / (10 ** 18)) * 10000) / 10000 : parseInt(((this.state.cap - parseInt(rs[1])) / (10 ** 18)) * 10000) / 10000,
        });
      });

    if (loop) {
      setTimeout(() => {
        this.herodashSale.methods.weiRaised().call()
          .then((r) => {
            this.setState({
              weiRaised: r,
              completed: parseInt(r) > 500*10**18 ? parseInt(((parseInt(r) - 500*10**18) / this.state.cap) * 10000) / 100 : parseInt(((parseInt(r)) / this.state.cap) * 10000) / 100,
              bnbRemaining: parseInt(r) > 500*10**18 ? parseInt(((this.state.cap - parseInt(r) - 500*10**18) / (10 ** 18)) * 10000) / 10000 : parseInt(((this.state.cap - parseInt(r)) / (10 ** 18)) * 10000) / 10000,
            });
          });

      }, 30000);
    }
  }

  getEquivalentTopkens = () => {
    const { rate, bnbAmount } = this.state;
    return (rate) * bnbAmount;
  }

  buyHerodash = () => {
    if (this.props.account) {
      const { bnbAmount } = this.state;

      if (bnbAmount < 0.008) {
        return this.props.showError("The minimum amount to buy is 0.008!");
      }

      this.herodashSale.methods.buyTokens(this.props.account).send({
        from: this.props.account,
        value: this.props.web3.utils.toWei(`${bnbAmount}`, 'ether'),
      })
        .once('transactionHash', (hash) => {
          this.props.showTransaction(hash, false, false, 10);
        })
        .then((x) => {
          this.props.showTransaction(x.transactionHash, true, true, 10);
          this.loadbalance();
          this.loadStats(false);
        })
        .catch((e) => {
          console.log(e);
          this.props.showTransaction("", true, false, 10);
        });

    } else {
      this.props.showError("Please connect your wallet first!");
    }
  }

  render() {
    const { bnbRemaining, completed, bnbAmount, bnbBalance, round } = this.state;

    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">BUY HERODASH</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">HERODASH Presale for liquidity</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <p className='text-white font-poppins font-semibold text-lg'><span className="material-icons-outlined text-other3 align-text-bottom">shopping_cart</span> Presale Round{round}: 101,25M $HEDA for 500BNB</p>
            <div className="relative pt-1 mt-2">
              <p className="text-white font-poppins text-lg">{completed}% Completed ({bnbRemaining} BNB remaining)</p>
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
                <div style={{ width: `${completed}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-600"></div>
              </div>
            </div>
            <div className="pt-6">
              <label className="text-other2 font-poppins"> You have {bnbBalance} BNB</label>
              <div className="flex space-x-2">
                <input min={0.008} value={bnbAmount} onChange={(e) => this.setState({ bnbAmount: e.target.value })} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="number" />
                <span className="text-white font-poppins text-lg self-center">BNB</span>
              </div>
              <p className="text-white font-poppins text-lg">You will receive <span className="text-other1">{this.getEquivalentTopkens()}</span> Herodash tokens</p>
              {this.props.account !== null && <div className="text-left mt-4">
                <button onClick={this.buyHerodash} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">Buy Herodash</button>
              </div>}
              {this.props.account === null && <div className="text-left mt-4">
                <button onClick={this.props.connectWallet} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">Connect wallet to buy</button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
