import React, { Component } from 'react';

export default class Audits extends Component {
  render() {
    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">AUDITS</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">For more trust, HERODASH will get audited</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <p className="text-center text-white font-poppins font-semibold text-xl"><span className="material-icons-outlined text-other3 align-text-bottom">security</span>  Audits</p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">TechRate:  <span className="text-other3">Contacted</span> </span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">Certik:  <span className="text-other3">Not yet</span></span>
            </p>
          </div>
        </div>

      </div>
    )
  }
}
