import React, { Component } from 'react';

import erc20ABI from '../abis/erc20.json';
import constants from '../constants';

export default class Deal extends Component {
  state = {
    account: this.props.account,
    tokenNameDb: {},
    dealId: "",
    onTransaction: false,
    user1Address: "",
    user2Address: "",
    token1Address: "",
    token2Address: "",
    token1Name: "",
    token2Name: "",
    token1Amount: 0,
    token2Amount: 0,
    approveToken: true,
    allowedToken: new this.props.web3.utils.BN('0'),
    newTransaction: true,
    transactionState: 0,
    timestamp: 0,
    loaded: false,
  };

  herodashFire = this.props.herodashFire;

  componentDidUpdate() {
    if (this.state.account !== this.props.account) {
      this.setState({ account: this.props.account });
    }
  }

  getTokenName = async (address) => {
    const ERC20token = new this.props.web3.eth.Contract(erc20ABI.abi, address);
    const name = await ERC20token.methods.name().call();
    return name;
  }

  checkAllowance = () => {

    if (!this.state.account) return;

    if (this.state.newTransaction) {

      if (this.state.allowedToken.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.token1Amount}`, 'ether')).cmp(this.state.allowedToken) === 1) {
          this.setState({ approveToken: true });
        } else {
          this.setState({ approveToken: false });
        }
      } else {
        const ERC20token = new this.props.web3.eth.Contract(erc20ABI.abi, this.state.token1Address);

        ERC20token.methods.allowance(this.state.account, constants.herodashFireAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);


            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.token1Amount}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedToken: allowance, approveToken: true });
            } else {
              this.setState({ allowedToken: allowance, approveToken: false });
            }
          });
      }

    } else {

      if (this.state.allowedToken.cmp(new this.props.web3.utils.BN('0')) === 1) {
        if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.token2Amount}`, 'ether')).cmp(this.state.allowedToken) === 1) {
          this.setState({ approveToken: true });
        } else {
          this.setState({ approveToken: false });
        }
      } else {
        const ERC20token = new this.props.web3.eth.Contract(erc20ABI.abi, this.state.token2Address);

        ERC20token.methods.allowance(this.state.account, constants.herodashFireAddress).call()
          .then((res) => {
            const allowance = new this.props.web3.utils.BN(res);


            if (new this.props.web3.utils.BN(this.props.web3.utils.toWei(`${this.state.token2Amount}`, 'ether')).cmp(allowance) === 1 || allowance.cmp(new this.props.web3.utils.BN('0')) === 0) {
              this.setState({ allowedToken: allowance, approveToken: true });
            } else {
              this.setState({ allowedToken: allowance, approveToken: false });
            }
          });
      }

    }
  }

  approveERC20Token = () => {
    const amountToApprove = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    const address = this.state.newTransaction ? this.state.token1Address : this.state.token2Address;

    const ERC20token = new this.props.web3.eth.Contract(erc20ABI.abi, address);

    ERC20token.methods.approve(constants.herodashFireAddress, amountToApprove)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ approveToken: false });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });

  }

  updateToken = async (address, token1 = true) => {

    if (token1) {
      this.setState({ token1Address: address });
    } else {
      this.setState({ token2Address: address });
    }

    if (address.length === 42) {
      const name = await this.getTokenName(address);

      if (token1) {
        this.setState({ token1Name: name });
        this.checkAllowance();
      } else {
        this.setState({ token2Name: name });
      }
    }
  }

  updateAmount = async (value, token1 = true) => {
    const newValue = parseInt(value * 100000) / 100000;

    if (token1) {
      this.setState({ token1Amount: newValue });
      this.checkAllowance();
    } else {
      this.setState({ token2Amount: newValue });
    }

  }

  startDeal = () => {
    this.setState({
      onTransaction: true,
      newTransaction: true,
      transactionState: 0,
      token1Address: "",
      token2Address: ""
    });
  }

  continueDeal = () => {
    if (!this.state.dealId) {
      return;
    }

    this.props.showSuccess('Loading transaction data...', 20);

    // Get transaction details
    this.herodashFire.methods.getTransactionDetails(this.state.dealId).call()
      .then(async ({ user0, user1, token0, token1, amount0, amount1, state, timestamp }) => {



        const name0 = await this.getTokenName(token0);
        const name1 = await this.getTokenName(token1);

        this.setState({
          onTransaction: true, newTransaction: this.state.account === user0.toLowerCase(), user1Address: user0.toLowerCase(), user2Address: user1.toLowerCase(),
          token1Address: token0, token2Address: token1, token1Amount: this.props.web3.utils.fromWei(amount0, 'ether'),
           token2Amount: this.props.web3.utils.fromWei(amount1, 'ether'),
          transactionState: parseInt(state), timestamp, loaded: true, token1Name: name0, token2Name: name1
        })
      });

  }

  initiateTransaction = () => {
    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    const {user2Address, token1Address, token2Address, token1Amount, token2Amount} = this.state;

    if (!user2Address || !token1Address || !token2Address || !token1Amount || !token2Amount) {
      return;
    }
    

    this.herodashFire.methods.initiateTransaction(
      user2Address,
      token1Address,
      token2Address,
      this.props.web3.utils.toWei(`${token1Amount}`, 'ether'),
      this.props.web3.utils.toWei(`${token2Amount}`, 'ether'),
      )
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showSuccess(`Transaction succeeded!, Send the transaction ID: ${x.events['TransactionUpdate'].returnValues["id"]} to user2`, 30);
        this.setState({ transactionState: 1, loaded: true, dealId: x.events['TransactionUpdate'].returnValues["id"] });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });
  }

  completeTransaction = () => {
    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    if (!this.state.dealId) {
      return;
    }

    this.herodashFire.methods.participate(this.state.dealId)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showSuccess(`Transaction succeeded!, See on explorer: ${constants.explorerLink}${x.transactionHash}`);
        this.setState({ transactionState: 3 });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });
  }

  claim = () => {
    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    if (!this.state.dealId) {
      return;
    }

    this.herodashFire.methods.claim(this.state.dealId)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showSuccess(`Transaction succeeded!, See on explorer: ${constants.explorerLink}${x.transactionHash}`);
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });
  }

  refund = () => {
    if (!this.state.account) {
      return this.props.showError("Please connect your wallet first!");
    }

    if (!this.state.dealId) {
      return;
    }

    this.herodashFire.methods.refund(this.state.dealId)
      .send({ from: this.state.account })
      .once('transactionHash', (hash) => {
        this.props.showTransaction(hash, false, false, 10);
      })
      .then((x) => {
        this.props.showTransaction(x.transactionHash, true, true, 10);
        this.setState({ transactionState: 4 });
      })
      .catch((e) => {
        console.log(e);
        this.props.showTransaction("", true, false, 10);
      });
  }

  getTransactionStatus = () => {
    switch(this.state.transactionState) {
      case 0: {
        return "Not Started";
      }
      case 1: {
        return "Initiated, waiting for user2";
      }
      case 2: {
        return "Ready, claim your tokens";
      }
      case 3: {
        return "Complete, user1 and user2 claimed their tokens";
      }
      case 4: {
        return "Refunded";
      }
      default: {}
    }
  }

  render() {

    const {
      onTransaction, dealId, user2Address, token1Address, token1Name,
      token2Address, token2Name, token1Amount, token2Amount, approveToken,
      newTransaction, user1Address, transactionState, loaded
    } = this.state;

    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2 px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">DEAL</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">Deal tokens with any user on BSC</p>
        </div>
        <div className="px-2 mt-8">
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <p className='text-white font-poppins text-lg'><span className="material-icons-outlined text-other3 align-text-bottom">currency_exchange</span>&nbsp; DEAL TOKENS <span className="text-other3"></span></p>

            {onTransaction && <p className="text-white font-poppins mt-2 break-all">Transaction ID:  <span className="text-other1"> {dealId !=="" ? dealId : 'Not Generated Yet'}</span></p>}
            {onTransaction && <p className="text-white font-poppins mt-2">(Very important, you need to keep the Transaction ID)</p>}

            {onTransaction && <div className="mt-3">
              <div className="mt-2">
                <label className="text-other2 font-roboto text-lg">User1 address</label>
                <input placeholder="User1 Address" readOnly value={newTransaction ? this.state.account : user1Address} className="w-full h-11 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="text" />
              </div>
              <div className="mt-2">
                <label className="text-other2 font-roboto text-lg">User2 address</label>
                <input placeholder="User2 Address" readOnly={loaded} value={user2Address} onChange={(e) => this.setState({ user2Address: e.target.value })} className="w-full h-11 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="text" />
              </div>

              <div className="">
                <div className="mt-3">
                  <label className="text-other2 font-roboto text-lg">User1 will send {token1Name ? `(${token1Amount} ${token1Name})` : ''}</label>
                  <input placeholder="TOKEN1 Address" readOnly={loaded} value={token1Address} onChange={(e) => this.updateToken(e.target.value, true)} className="w-full h-11 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="text" />
                  <input placeholder="TOKEN1 Amount" readOnly={loaded} value={token1Amount} onChange={(e) => this.updateAmount(e.target.value, true)} className="w-full h-11 bg-primary-light px-3 text-lg font-poppins text-white outline-none mt-2" type="number" />
                </div>
                <div className="mt-3">
                  <label className="text-other2 font-roboto text-lg">User2 will send {token2Name ? `(${token2Amount} ${token2Name})` : ''}</label>
                  <input placeholder="TOKEN2 Address" readOnly={loaded} value={token2Address} onChange={(e) => this.updateToken(e.target.value, false)} className="w-full h-11 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="text" />
                  <input placeholder="TOKEN2 Amount" readOnly={loaded} value={token2Amount} onChange={(e) => this.updateAmount(e.target.value, false)} className="w-full h-11 bg-primary-light px-3 text-lg font-poppins text-white outline-none mt-2" type="number" />
                </div>
              </div>

              <div className="mt-4">
                {(this.props.account !== null && !approveToken && token1Address && token2Address && transactionState === 0) && <div className="mt-6">
                  <button onClick={this.initiateTransaction} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Initiate transaction</button>
                </div>}

                {(this.props.account !== null && !approveToken && token1Address && token2Address && transactionState === 1 && user2Address === this.state.account) && <div className="mt-6">
                  <button onClick={this.completeTransaction} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Complete transaction</button>
                </div>}

                {(this.props.account !== null && token1Address && token2Address && transactionState === 2 && (user1Address === this.state.account || user2Address === this.state.account)) && <div className="mt-6">
                  <button onClick={this.claim} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Claim tokens</button>
                </div>}

                {(this.props.account !== null && token1Address && token2Address && transactionState === 1 && user1Address === this.state.account) && <div className="mt-6">
                  <button onClick={this.refund} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Refund (only after 10mins)</button>
                </div>}

                {(this.props.account !== null && approveToken && token1Address && newTransaction && transactionState === 0) && <div className="mt-6">
                  <button onClick={this.approveERC20Token} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve { token1Name }</button>
                </div>}

                {(this.props.account !== null && approveToken && !newTransaction && user2Address === this.state.account && transactionState === 1) && <div className="mt-6">
                  <button onClick={this.approveERC20Token} className="bg-other3 text-white w-full py-2 rounded-md font-poppins font-semibold hover:bg-opacity-75">Approve {token2Name}</button>
                </div>}

                {(this.props.account !== null && onTransaction) && <p className="font-poppins text-white mt-2">STATUS: <span className="text-other3 font-semibold">{this.getTransactionStatus()}</span></p>

                }
              </div>
            </div>}

            <div className="mt-4">
              {this.props.account === null &&
                <button onClick={this.props.connectWallet} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">
                  Connect wallet to deal tokens
                </button>}
              {(this.props.account !== null && !onTransaction) && <div>
                <p><button onClick={this.startDeal} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">New transaction</button></p>
                <p className="text-center mt-2 text-white font-poppins">OR</p>
                <div>
                  <div className="mt-2">
                    <label className="text-other2 font-poppins text-lg">TRANSACTION ID</label>
                    <input value={dealId} onChange={(e) => this.setState({ dealId: e.target.value })} className="w-full h-12 bg-primary-light px-3 text-lg font-poppins text-white outline-none" type="text" />
                  </div>
                  <p className="mt-2"><button onClick={this.continueDeal} className="bg-other3 px-2 py-2 rounded-sm text-white font-poppins font-semibold hover:bg-opacity-75 w-full">Continue a transaction</button></p>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
