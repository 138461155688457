import React, { Component } from 'react';

export default class Home extends Component {


  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.charSet = "utf-8"
    document.getElementById("an").appendChild(script);
  }

  render() {

    const {
      herodashInWallet, totalSupply, transferTax,
      totalburned, account, herodashPrice, marketCap, connectWallet,
      herodashStacked, herodashToHarvest, herodashValue, tvl
    } = this.props;

    return (
      <div className="h-full pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2 px-6 py-6">
          <p className="text-center text-3xl font-semibold font-poppins text-other2">Herodash</p>
          <p className="text-center md:text-xl font-semibold text-white font-poppins">Community utility token on BSC</p>
        </div>
        <div className="bg-primary-light mx-3 pb-4">
          <div className="md:flex py-8 justify-center md:space-x-8 space-y-3 md:space-y-0">
            <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl shadow-md">
              <p className="text-lg md:text-3xl font-semibold text-white font-poppins">Staking</p>
              <p className="mt-4 text-white font-poppins font-semibold text-lg">Herodash in wallet</p>
              <p className="text-other3 font-poppins font-semibold text-xl">{herodashInWallet}</p>
              <p className="mt-4 text-white font-poppins font-semibold text-lg">Herodash staked</p>
              <p className="text-other3 font-poppins font-semibold text-xl">{herodashStacked}</p>
              <p className="mt-4 text-white font-poppins font-semibold text-lg">Herodash to harvest</p>
              <p className="text-other3 font-poppins font-semibold text-xl">{herodashToHarvest}</p>
              <p className="mt-4 text-white font-poppins font-semibold text-lg">Total Herodash value</p>
              <p className="text-other3 font-poppins font-semibold text-xl">${herodashValue}</p>
              <p className="mt-10">
                <button onClick={account ? null : connectWallet} className="bg-primary-light text-white w-full text-lg text-center px-2 font-semibold font-poppins rounded-xl py-3 hover:bg-opacity-80 break-all">{account ? account : 'Connect Wallet'}</button>
              </p>
            </div>
            <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl shadow-md">
              <p className="text-lg md:text-3xl font-semibold text-white font-poppins">Herodash stats</p>
              <p className="mt-4 text-white font-poppins text-xl">TVL</p>
              <p className="text-other3 font-poppins font-semibold text-xl">${tvl}</p>
              <p className="mt-2 text-white font-poppins font-semibold text-lg">Herodash price</p>
              <p className="text-other3 font-poppins font-semibold text-xl">${herodashPrice}</p>
              <p className="mt-2 text-white font-poppins font-semibold text-lg">Total supply</p>
              <p className="text-other3 font-poppins font-semibold text-xl">{totalSupply}</p>
              <p className="mt-2 text-white font-poppins font-semibold text-lg">Total Burned</p>
              <p className="text-other3 font-poppins font-semibold text-xl">{totalburned}</p>
              <p className="mt-2 text-white font-poppins font-semibold text-lg">Market Cap</p>
              <p className="text-other3 font-poppins font-semibold text-xl">${marketCap}</p>
              <p className="mt-2 text-white font-poppins font-semibold text-lg">Transfer Tax</p>
              <p className="text-other3 font-poppins font-semibold text-xl">{transferTax}%</p>
            </div>
          </div>
          <div className="md:flex py-0 justify-center md:space-x-8 space-y-3 md:space-y-0">
            <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl shadow-md">
              <p className="text-lg md:text-3xl font-semibold text-white font-poppins">Usefull Links</p>
              <p className="mt-4 text-white font-poppins text-xl">
                <span className="material-icons-outlined text-other3 align-text-bottom">people</span>
                &nbsp; Community
              </p>
              <p className="text-white mt-1 font-poppins font-semibold">Telegram Group: <a className="text-other3" href="https://t.me/herodashOfficial" rel="noreferrer" target="_blank">@herodashOfficial</a></p>
              <p className="text-white mt-1 font-poppins font-semibold break-all">Telegram Channel: <a className="text-other3" href="https://t.me/herodashOfficialAnnouncements" rel="noreferrer" target="_blank">@herodashOfficialAnnouncements</a></p>
              <p className="mt-5 text-white font-poppins text-xl">
                <span className="material-icons-outlined text-other3 align-text-bottom">show_chart</span>
                &nbsp; Charts
              </p>
              <p className="text-white mt-1 font-poppins font-semibold">Poocoin: <a className="text-other3" href="fz" target="_blank">-</a></p>
              <p className="mt-5 text-white font-poppins text-xl">
                <span className="material-icons-outlined text-other3 align-text-bottom">format_list_bulleted</span>
                &nbsp; Listing
              </p>
              <p className="text-white mt-1 font-poppins font-semibold">Coinmarketcap: <a className="text-other3" href="fz" target="_blank">-</a></p>
              <p className="text-white mt-1 font-poppins font-semibold">Coingecko: <a className="text-other3" href="fz" target="_blank">-</a></p>
            </div>
            <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl shadow-md">
              <p className="text-xl md:text-3xl font-semibold text-white font-poppins">News</p>
              <div className="mt-2 max-h-card overflow-y-scroll" id="an">
                <a className="twitter-timeline" data-theme="dark" href="https://twitter.com/herodashBSC?ref_src=twsrc%5Etfw">Tweets by HERODASHBSC</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
