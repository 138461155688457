module.exports = {
  capSupply: 750000000,
  herodashAddress: "0x4648067bb23580f9180d898b7c56269725b66012",
  bnb_busd_pairAddress: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
  bnb_herodash_pairAddress: "0xc1Bce42d087A31523e881af42185Ad1D25f81403",
  wbnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  masterChiefAddress: "0x3c68fea915dd3f9a4f79a4f9cf77b8aab19c9ac5",
  herodashFireAddress: "0xDe735Db98634F7B346ff31BAf4bb981D5A10BAF4",
  herodashSaleAddress: "0xc3244e521af85ce112190ae8113576864848cb31",
  pancakeRouterAddress: "0x10ed43c718714eb63d5aa57b78b54704e256024e",
  herodashKeeper: "0xfB2a07ac850778B56dad4bc0a43eF9C06E60d58d",
  poolCount1: 0,
  poolCount2: 0,
  cakeAddress: "",
  busdAddress: "",
  btcbAddress: "",
  pools: [{
    address: "",
    pairAddress: "",
    isTokenZero: false,
  }],
  explorerLink: 'https://bscscan.com/tx/',
  knownAddresses: {
    "0x05ff2b0db69458a0750badebc4f9e13add608c7f": "PancakeSwap Router v1",
    "0x10ed43c718714eb63d5aa57b78b54704e256024e": "PancakeSwap Router v2",
    "0x3c68fea915dd3f9a4f79a4f9cf77b8aab19c9ac5": "Herodash MasterChef",
    "0xde735db98634f7b346ff31baf4bb981d5a10baf4": "Herodash Utility",
    "0xcde540d7eafe93ac5fe6233bee57e1270d3e330f": "BakerySwap Router",
    "0x73feaa1ee314f8c655e354234017be2193c9e24e": "BurgerSwap"
  }
};