import React, { Component } from 'react';
import Chart from "react-google-charts";

export default class Roadmap extends Component {
  render() {
    return (
      <div className="pb-3">
        <div className="bg-primary bg-opacity-60 border-b-2 border-other2  px-6 py-6">
          <p className="text-center text-3xl font-medium font-poppins text-other2">Roadmap</p>
          <p className="text-center md:text-xl font-medium text-white font-poppins">An exciting and rich roadmap</p>
        </div>
        <div className="hidden xl:block max-w-5xl mx-auto mt-8 px-2">
          <p className="text-center text-white font-poppins font-medium text-xl md:text-2xl">Herodash Tokenomics</p>
          <Chart
            height={500}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Token use', 'Amount'],
              ['27% Crowdsale', 202500000],
              ['26,7% Liquidity', 200000000],
              ['40% Farming', 300000000],
              ['4,3% Airdrop', 32500000],
              ['2% Dev', 15000000],
            ]}
            options={{
              titlePosition: 'none',
              backgroundColor: '#455F69',
              colors: ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"],
              is3D: false,
              pieHole: 0.4,
              pieStartAngle: 90,
              legend: {
                textStyle: { color: 'white', fontName: 'poppins', fontSize: '15', bold: true },
                position: "bottom",
              },
              chartArea: {
                top: '20',
                width: '100%'
              },
              pieSliceTextStyle: {
                bold: true
              }
            }}
          />
        </div>
        <div className="hidden lg:block xl:hidden max-w-5xl w-full mx-auto mt-5 px-2">
          <p className="text-center text-white font-poppins font-medium text-lg">Herodash Tokenomics</p>
          <Chart
            height={400}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Token use', 'Amount'],
              ['27% Crowdsale', 202500000],
              ['26,7% Liquidity', 200000000],
              ['40% Farming', 300000000],
              ['4,3% Airdrop', 32500000],
              ['2% Dev', 15000000],
            ]}
            options={{
              titlePosition: 'none',
              backgroundColor: '#455F69',
              colors: ["#f4a261", "#e76f51", "#2a9d8f", "#45ac4b", "#e9c46a"],
              is3D: false,
              pieHole: 0.4,
              pieStartAngle: 90,
              chartArea: { width: '60%', top: '20', bottom: '20' },
              legend: {
                textStyle: { color: 'white', fontName: 'poppins', fontSize: '16', bold: true },
              }
            }}
          />
        </div>
        <div className="lg:hidden max-w-5xl w-full mx-auto mt-5 px-2">
          <p className="text-center text-white font-poppins font-medium text-lg">Herodash Tokenomics</p>
          <Chart
            height={250}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Token use', 'Amount'],
              ['27% Crowdsale', 202500000],
              ['26,7% Liquidity', 200000000],
              ['40% Farming', 300000000],
              ['4,3% Airdrop', 32500000],
              ['2% Dev', 15000000],
            ]}
            options={{
              titlePosition: 'none',
              backgroundColor: '#455F69',
              colors: ["#f4a261", "#e76f51", "#2a9d8f", "#45ac4b", "#e9c46a"],
              is3D: false,
              pieHole: 0.4,
              pieStartAngle: 90,
              chartArea: { width: '98%', top: '20', bottom: '20' },
              legend: {
                textStyle: { color: 'white', fontName: 'poppins', bold: true },
              },
              pieSliceText: "none",
            }}
          />
        </div>
        <div className="px-2">
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto mt-2 xl:mt-8 shadow-md">
            <p className="text-center text-white font-poppins font-semibold text-xl">PHASE 0 (NOW)</p>
            <p className="mt-2">
              <input type="checkbox" checked readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">Presale Round1 (101,25M $HEDA for 500 BNB)</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">Presale Round2 (101,25M $HEDA for 500 BNB)</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">0% Transaction tax</span>
            </p>
          </div>
          <p className="w-1 h-12 bg-other1 mx-auto"></p>
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto shadow-md">
            <p className="text-center text-white font-poppins font-semibold text-xl">PHASE 1</p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Add and Lock Liquidity on Pancakeswap</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Farming / Staking</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">5% Transaction tax (3% Burn + 2% Dev fee)</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Listing on CMC and Coingecko</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Tool1: Escrow tool to buy and sell on BSC, Polygon and Ethereum</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Tool2: Emergency withdrawal tool to withdraw from any pool</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Tool3: Token Allowance Checker and Remover </span>
            </p>
          </div>
          <p className="w-1 h-12 bg-other2 mx-auto"></p>
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto shadow-md">
            <p className="text-center text-white font-poppins font-semibold text-xl">PHASE 2</p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">5% Transaction tax (2% Burn + 2% Farming + 1% Dev Fee)</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">Lottery with 20% Burn</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">Airdrop</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other1 font-poppins font-medium md:text-lg">More utility tools</span>
            </p>
          </div>
          <p className="w-1 h-12 bg-other1 mx-auto"></p>
          <div className="bg-primary max-w-xl w-full px-6 py-4 rounded-xl mx-auto shadow-md">
            <p className="text-center text-white font-poppins font-semibold text-xl">PHASE 3</p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">0% Transaction tax</span>
            </p>
            <p className="mt-2">
              <input type="checkbox" checked={false} readOnly className="w-5 h-5 align-text-bottom" /> &nbsp; <span className="text-other2 font-poppins font-medium md:text-lg">Listing on Exchanges</span>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
